import Button from 'components/atoms/Button'
import PurchaseHistoryMetrics from 'components/atoms/PurchaseHistoryMetrics'
import RewardBanner from 'components/atoms/RewardBanner'
import PurchasedItemsList from 'components/molecules/PurchasedItemsList'
import usePurchaseHistory from 'hooks/usePurchaseHistory'
import { Link } from 'react-router-dom'
import { PATHS } from 'utils/consts'
import './style.scss'

const PurchaseHistory = () => {
  const { purchasedItemsCount, purchasedItemsList, pageCount, getPurchasedItems } =
    usePurchaseHistory()

  return (
    <div className="purchase-history">
      <header className="purchase-history__header">
        <PurchaseHistoryMetrics purchasedItemsCount={purchasedItemsCount} />
        <Link className="purchase-history__link" to={PATHS.rewardShop}>
          <Button className="btn--outline">Przejdź do sklepu</Button>
        </Link>
      </header>
      <RewardBanner />
      <PurchasedItemsList
        purchasedItemsList={purchasedItemsList || []}
        pageCount={pageCount}
        getPurchasedItems={getPurchasedItems}
      />
    </div>
  )
}

export default PurchaseHistory
