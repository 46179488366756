import useLeadCategories from 'hooks/useLeadCategories'
import { InviteCategoriesProps } from './interface'
import CandidateLeads from 'components/organisms/CandidateLeads'

import './style.scss'

const InviteCategories = ({ onNextStepClick }: InviteCategoriesProps) => {
  const { leadCategories: leadCategoriesData } = useLeadCategories()

  return (
    <div className="invite-categories">
      <h2 className="invite-categories__title">
        Potwierdź o czym <br /> chciałbyś porozmawiać?
      </h2>
      <p className="invite-categories__description">Możesz wybrać maksymalnie 4 kategorie.</p>
      <CandidateLeads
        leadCategoriesData={leadCategoriesData || []}
        nextFormStep={() => onNextStepClick(['leadCategories'])}
        buttonType="button"
      />
    </div>
  )
}

export default InviteCategories
