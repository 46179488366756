import { ILeadCategory } from 'models/leadCategory'
import { ENDPOINTS } from 'utils/consts'
import useData from './useData'

const useLeadCategories = () => {
  const { data: leadCategories, ...rest } = useData<ILeadCategory[]>(ENDPOINTS.leadCategories, {
    dataLocation: 'items'
  })

  return { leadCategories, ...rest }
}

export default useLeadCategories
