import { ISelectOption } from 'models/selectOption'
import { ITaxOffice } from 'models/taxOffice'
import { ENDPOINTS } from 'utils/consts'
import { transformResponseDataToSelectOptions } from 'utils/TransformResponseData/toSelectOptions'
import useData from './useData'

const useTaxOffices = () => {
  const { data: taxOfficesOptions, ...rest } = useData<ITaxOffice[], ISelectOption[]>(
    ENDPOINTS.taxOffices,
    {
      dataLocation: 'items',
      transformResponseData: transformResponseDataToSelectOptions
    }
  )

  return { taxOfficesOptions, ...rest }
}

export default useTaxOffices
