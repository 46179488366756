import LineSeparator from 'components/atoms/LineSeparator'
import Tile from 'components/atoms/Tile'
import useHomePageExpansion from 'pages/HomePage/hooks/useHomePageExpansion'
import './style.scss'
import parse from 'html-react-parser'

const ExpansionSection = () => {
  const { data, image } = useHomePageExpansion()

  return (
    <section className="expansion-section">
      <img
        className="expansion-section__bg-image"
        src={
          image?.expansionBackgroundImage || require('assets/images/default-expansion-bg-img.jpg')
        }
      />
      <div className="expansion-section__content-wrapper">
        <Tile className="content-wrapper__card" shadow="normal" borderTop>
          <h1 className="card__title">{data?.expansionTitle}</h1>
          <LineSeparator />
          <p className="card__content">{parse(data?.expansionContent || '')}</p>
        </Tile>
      </div>
    </section>
  )
}

export default ExpansionSection
