import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'
import { IHomePageDescription } from '../models/homePageDescription'

const useHomePageDescription = () => {
  const { data } = useData<IHomePageDescription>(ENDPOINTS.homePageDescription)

  return { data }
}

export default useHomePageDescription
