import Button from 'components/atoms/Button'
import ModalBaseStructure from 'components/atoms/ModalBaseStructure'
import { useModals } from 'providers/modals/context'
import { SidewayModalProps } from './interface'
import './style.scss'

const SidewayModal = ({ children, title, productInfo, footer, button }: SidewayModalProps) => {
  const { closeModal } = useModals()

  const { name: productName, price: productPrice } = productInfo
  const { title: footerTitle, content: footerContent } = footer
  const { text: buttonText, disable: disableButton, onClick: onButtonClick } = button

  return (
    <ModalBaseStructure>
      <div className="sideway-modal">
        <div className="sideway-modal__header">
          <h3 className="sideway-modal__title">{title}</h3>
          <i className={`sideway-modal__xmark fas fa-xmark`} onClick={closeModal} />
        </div>
        <ol className="sideway-modal__body">
          <li className="sideway-modal__product-info sideway-modal__process-step">
            <p className="product-info__name">{productName}</p>
            <h1 className="product-info__price">{productPrice}</h1>
          </li>
          {children}
        </ol>
        <div className="sideway-modal__footer">
          <div className="footer__text-wrapper">
            <p className="footer__title">{footerTitle}</p>
            <h4 className="footer__content">{footerContent}</h4>
          </div>
          <Button onClick={onButtonClick} disable={disableButton}>
            {buttonText}
          </Button>
        </div>
      </div>
    </ModalBaseStructure>
  )
}

export default SidewayModal
