import { IServerResponse } from 'models/serverResponse'
import { format } from 'date-fns'
import { IFilledCandidateFormData, InviteFormDataApi } from 'pages/InvitePage/interface'
import { useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'
import axiosDjango from 'setup/axios/defaultInstance'
import { ENDPOINTS, LOCAL_STORAGE } from 'utils/consts'
import { parseApiErrors } from 'utils/parseApiErrors'
import useData from './useData'

const useCandidateSurvey = () => {
  const [errorMessage, setErrorMessage] = useState<IServerResponse['errors']>({})
  const hash = localStorage.getItem(LOCAL_STORAGE.candidateHash)
  const { data: userData } = useData<IFilledCandidateFormData>(
    generatePath(ENDPOINTS.candidateSurveyDetails, { hash: `${hash}` })
  )

  const formatedData = useMemo(() => {
    if (!userData) {
      return
    }

    const { preferredMeetingTime, ...rest } = userData

    const formatedDate = preferredMeetingTime
      ? format(new Date(preferredMeetingTime), 'yyyy-MM-dd')
      : ''

    const date = typeof preferredMeetingTime === 'string' ? new Date(formatedDate) : undefined

    const time =
      typeof preferredMeetingTime === 'string' ? new Date(preferredMeetingTime) : undefined

    return { date, time, ...rest }
  }, [userData])

  const sendData = async (data: Partial<InviteFormDataApi>): Promise<IServerResponse> => {
    try {
      const hash = localStorage.getItem(LOCAL_STORAGE.candidateHash)
      await axiosDjango.patch(generatePath(ENDPOINTS.candidateSurvey, { hash: `${hash}` }), data)

      return { succeed: true }
    } catch (error) {
      const serverResponse = parseApiErrors(error)

      setErrorMessage(serverResponse.errors)

      return { succeed: false }
    }
  }

  const reject = async () => {
    try {
      const hash = localStorage.getItem(LOCAL_STORAGE.candidateHash)
      await axiosDjango.post(generatePath(ENDPOINTS.candidateSurveyReject, { hash: `${hash}` }))
    } catch (error) {
      console.error(error)
    }
  }

  return { formatedData, userData, errorMessage, sendData, reject }
}

export default useCandidateSurvey
