import classNames from 'classnames'
import ArticleTypeTag from 'components/atoms/ArticleTypeTag'
import LineSeparator from 'components/atoms/LineSeparator'
import CardFooter from 'components/molecules/CardFooter'
import { ArticleCardItemProps } from './interface'
import parse from 'html-react-parser'
import './style.scss'
import { generatePath } from 'react-router-dom'
import { PATHS } from 'utils/consts'

const ArticleCardItem = ({ variant, article }: ArticleCardItemProps) => {
  const { id, title, description, createdAt } = article

  const getTagOrientation = () => {
    switch (variant) {
      case 'imgLeft':
        return 'right'
      case 'imgRight':
        return 'left'
    }
  }

  return (
    <div
      className={classNames('article-card-item', {
        'article-card-item--imgLeft': variant === 'imgLeft',
        'article-card-item--imgRight': variant === 'imgRight'
      })}>
      <div className="article-card-item__img-wrapper">
        <img className="img-wrapper__img" src={article.headerImage} alt="Zdjęcie artykułu" />
        <ArticleTypeTag
          className={classNames('img-wrapper__article-type-tag', {
            'img-wrapper__article-type-tag--right': variant === 'imgLeft'
          })}
          orientation={getTagOrientation()}>
          Artykuł
        </ArticleTypeTag>
      </div>
      <div className="article-card-item__content-wrapper">
        <h1 className="content-wrapper__title">{title}</h1>
        <LineSeparator />
        <div className="content-wrapper__description">{parse(description)}</div>
        <CardFooter
          className="content-wrapper__footer"
          text={createdAt}
          link={{
            text: 'Zobacz więcej',
            redirectionPath: generatePath(PATHS.articleDetails, { id })
          }}
        />
      </div>
    </div>
  )
}

export default ArticleCardItem
