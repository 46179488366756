import useData from 'hooks/useData'
import { IArticleDetails } from 'models/articleDetails'
import { generatePath, useParams } from 'react-router-dom'
import { ENDPOINTS } from 'utils/consts'
import { transformArticleDetailsResponseData } from 'utils/TransformResponseData/articleDetails'

const useArticleDetails = () => {
  const { id } = useParams()
  const { data: articleDetails } = useData<IArticleDetails>(
    generatePath(ENDPOINTS.articleDetails, { id }),
    {
      transformResponseData: transformArticleDetailsResponseData
    }
  )

  return { articleDetails }
}

export default useArticleDetails
