import Select from 'components/atoms/Select'
import { ISelectOption } from 'models/selectOption'
import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { IDENTIFIERS } from 'utils/consts'
import { valid } from 'utils/Validators/validators'
import Input from '../Input'
import { IdentifierFieldProps } from './interface'
import './style.scss'

const IdentifierField = ({ defaultOption, defaultValue }: IdentifierFieldProps) => {
  const { setValue: setInputValue } = useFormContext()

  const [selectedIdentifier, setSelectedIdentifier] = useState(
    defaultOption?.value || IDENTIFIERS[0].value
  )

  const handleIdentiferChange = useCallback(
    (value: ISelectOption['value']) => {
      setSelectedIdentifier(value)
      setInputValue(`${selectedIdentifier}`, '')
    },
    [selectedIdentifier, setInputValue]
  )

  return (
    <div className="identifier-field">
      <Select
        className="identifier-field__select"
        hideIndicators
        options={IDENTIFIERS}
        defaultOption={defaultOption || IDENTIFIERS[0]}
        placeholder=""
        onChange={handleIdentiferChange}
      />
      <Input
        className="identifier-field__input"
        name={`${selectedIdentifier}`}
        placeholder={selectedIdentifier === 'pesel' ? 'Pesel' : 'NIP'}
        defaultValue={defaultValue || undefined}
        validators={{
          required: valid.required,
          pattern: selectedIdentifier === 'pesel' ? valid.peselPattern : valid.nipPattern
        }}
      />
    </div>
  )
}

export default IdentifierField
