import Input from 'components/molecules/Input'
import { valid } from 'utils/Validators/validators'
import Button from 'components/atoms/Button'
import './style.scss'
import Checkbox from 'components/molecules/Checkbox'
import PhoneField from 'components/molecules/PhoneField'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import RegionSelect from 'components/molecules/RegionSelect'
import { useFormContext } from 'react-hook-form'
import { useModals } from 'providers/modals/context'
import RecommendationTermsAndConditionsModal from 'pages/ProfilePage/partials/RecommendationTermsAndConditionsModal'
import { MouseEvent } from 'react'

const CandidateInfo = () => {
  const { getValues } = useFormContext()
  const { openModal } = useModals()

  const showTermsAndConditions = (e: MouseEvent) => {
    // Prevent from checking/unchecking checkbox
    e.preventDefault()

    openModal(<RecommendationTermsAndConditionsModal />)
  }

  return (
    <div className="candidate-info">
      <Input
        name="firstName"
        placeholder="Imię"
        validators={{ required: valid.required, ...validSchemas.name }}
      />

      <PhoneField required />

      <RegionSelect required selectedRegionValue={getValues('region')} />

      <Checkbox name="termsAcceptance" validators={{ required: valid.required }}>
        {'Potwierdzam zapoznanie się z '}
        <span className="candidate-info__link" onClick={showTermsAndConditions}>
          regulaminem
        </span>
        {' i akceptację jego treści.'}
      </Checkbox>

      <Button className="candidate-info__btn" form="recommendationForm" type="submit">
        Gotowe
      </Button>
    </div>
  )
}

export default CandidateInfo
