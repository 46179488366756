import useHomePageLanding from 'pages/HomePage/hooks/useHomePageLanding'
import LandingCard from '../../components/LandingCard'
import './style.scss'

const LandingSection = () => {
  const { image, cards } = useHomePageLanding()

  return (
    <section className="landing-section">
      <img
        className="landing-section__bg-image"
        src={image?.headerBackgroundImage || require('assets/images/default-landing-img.jpg')}
      />
      {/* TODO: add support for multiple cards - carousel */}
      {cards && (
        <LandingCard
          title={cards[0].title}
          content={cards[0].content}
          button={{ text: cards[0].buttonText, link: cards[0].buttonLink }}
        />
      )}
    </section>
  )
}

export default LandingSection
