import Controller from 'components/atoms/Controller'
import Select from 'components/atoms/Select'
import useRegions from 'hooks/useRegions'
import { valid } from 'utils/Validators/validators'
import { RegionSelectProps } from './interface'

const RegionSelect = ({ required, selectedRegionValue }: RegionSelectProps) => {
  const { regionsOptions, defaultRegionOption } = useRegions()

  const getRegionOptionByValue = (value: string | number) => {
    return regionsOptions?.find((option) => option.value === value)
  }

  if (!regionsOptions) return null

  return (
    <Controller
      name="region"
      rules={{ required: required ? valid.required : false }}
      defaultValue={defaultRegionOption?.value}
      Element={Select}
      elementProps={{
        options: regionsOptions,
        placeholder: 'Placówka',
        defaultOption: selectedRegionValue
          ? getRegionOptionByValue(selectedRegionValue)
          : defaultRegionOption
      }}
    />
  )
}

export default RegionSelect
