import { useCallback, useEffect, useState } from 'react'
import { generatePath } from 'react-router-dom'
import axiosDjango from 'setup/axios/defaultInstance'
import { ENDPOINTS, LOCAL_STORAGE } from 'utils/consts'

const useUserStatus = () => {
  const [rejected, setRejected] = useState(false)

  const checkUserStatus = useCallback(async () => {
    try {
      const hash = localStorage.getItem(LOCAL_STORAGE.candidateHash)
      const res = await axiosDjango.get(
        generatePath(ENDPOINTS.candidateSurveyStatus, { hash: `${hash}` })
      )
      const userStatus = res?.data.status

      if (!res || userStatus === 'non-compliant' || userStatus === 'no_response') {
        setRejected(true)
      }
    } catch (error) {
      setRejected(true)
    }
  }, [])

  useEffect(() => {
    checkUserStatus()
  }, [checkUserStatus])

  return { rejected }
}

export default useUserStatus
