import StatusEclipse from 'components/atoms/StatusEclipse'
import StatusName from 'components/atoms/StatusName'
import { CandidateStatusProps } from './interface'
import './style.scss'

const CandidateStatus = ({ status }: CandidateStatusProps) => {
  return (
    <div className="status-box">
      <StatusEclipse status={status} />
      <StatusName status={status} />
    </div>
  )
}

export default CandidateStatus
