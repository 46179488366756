import { useState } from 'react'
import { FaAngleDown } from 'react-icons/fa'
import { CustomListingProps } from './interface'
import './style.scss'

const CustomListing = ({ label, defaultValue }: CustomListingProps) => {
  const [value] = useState(defaultValue)

  return (
    <div className="custom-listing">
      <label className="custom-listing__label">{label}</label>
      <div className="custom-listing__value">
        <span>{value}</span>
        <span className="custom-listing__icon">
          <FaAngleDown />
        </span>
      </div>
    </div>
  )
}

export default CustomListing
