import { IPayoutData } from 'models/payoutData'
import { ENDPOINTS } from 'utils/consts'
import useData from './useData'

const usePayoutData = (options?: { dontFetchOnMount: true }) => {
  const {
    data: payoutData,
    getData: getPayoutData,
    createData: createPayoutData,
    updateData: updatePayoutData
  } = useData<IPayoutData, IPayoutData[], Omit<IPayoutData, 'id'>>(ENDPOINTS.payoutData, {
    dontFetchOnMount: options?.dontFetchOnMount,
    dataLocation: 'items'
  })

  const mainPayoutData = payoutData && payoutData[0]

  return { mainPayoutData, getPayoutData, createPayoutData, updatePayoutData }
}

export default usePayoutData
