import Input from 'components/molecules/Input'
import Modal from 'components/organisms/Modal'
import { useModals } from 'providers/modals/context'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { getModalButtonText } from 'utils/getModalButtonText'
import { getModalTitlePrefix } from 'utils/getModalTitlePrefix'
import { handleApiResponse } from 'utils/handleApiResponse'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import { FiscalDataModalProps } from './_interface'

const CountyModal = ({ action, defaultValue, updateFiscalData }: FiscalDataModalProps) => {
  const methods = useForm()
  const { closeModal } = useModals()
  const formID = 'countyForm'

  const onSubmit = async (formValues: FieldValues) => {
    const { county } = formValues

    const response = await updateFiscalData({ county })

    handleApiResponse(response, closeModal, methods.setError)
  }

  return (
    <Modal
      title={`${getModalTitlePrefix(action)} powiat`}
      buttonText={getModalButtonText(action)}
      formID={formID}>
      <FormProvider {...methods}>
        <form id={formID} className="modal__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            name="county"
            placeholder="Powiat"
            defaultValue={defaultValue}
            validators={validSchemas.locationName}
          />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default CountyModal
