import classNames from 'classnames'
import { FaArrowLeft } from 'react-icons/fa'
import { FormCardProps } from './interface'
import './style.scss'

const FormCard = ({
  children,
  formStep,
  prevFormStep,
  leadCategoriesLength,
  className
}: FormCardProps) => {
  return (
    <div className={classNames('card', className)}>
      {formStep === 1 && (
        <span className="card__back" onClick={prevFormStep}>
          <FaArrowLeft />
        </span>
      )}

      <div className="card__progress">
        {formStep < 2 && (
          <>
            <span
              className={classNames('card__eclipse', { 'card__eclipse--active': formStep === 0 })}
            />
            <span
              className={classNames('card__eclipse', { 'card__eclipse--active': formStep === 1 })}
            />
          </>
        )}
      </div>
      <div className="card__description">
        <h3 className="card__title">
          {formStep === 0 &&
            (leadCategoriesLength > 1
              ? 'O czym Twój znajomy chciałby porozmawiać?'
              : 'Potwierdź, że Twój znajomy chce porozmawiać na temat:')}
          {formStep === 1 && 'Wpisz dane polecanej osoby'}
        </h3>
        <h4 className="card__subtitle">
          {formStep === 0 && leadCategoriesLength > 4 && 'Możesz wybrać maksymalnie 4 kategorie.'}
        </h4>
      </div>

      {children}
    </div>
  )
}

export default FormCard
