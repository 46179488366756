import classNames from 'classnames'
import Button from 'components/atoms/Button'
import LineSeparator from 'components/atoms/LineSeparator'
import useRouteChange from 'hooks/useRouteChange'
import useHomePageSummary from 'pages/HomePage/hooks/useHomePageSummary'
import { EHomePageSummaryButtonStyles } from 'pages/HomePage/models/homePageSummaryButtonStyles'
import './style.scss'
import parse from 'html-react-parser'

const SummarySection = () => {
  const { data } = useHomePageSummary()
  const { changeRoute } = useRouteChange()

  return (
    <section className="summary-section">
      <div className="summary-section__content-wrapper">
        <h1 className="summary-section__title">{data?.summaryTitle}</h1>
        <LineSeparator />
        <p className="summary-section__content">{parse(data?.summaryDescription || '')}</p>
        <div className="summary-section__footer">
          {data?.summaryButtons.map((button, index) => (
            <Button
              key={index}
              className={classNames({
                'btn--outline': button.style === EHomePageSummaryButtonStyles.Outlined
              })}
              onClick={() => changeRoute(button.url)}>
              {button.text}
            </Button>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SummarySection
