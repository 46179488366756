import { IconBoxProps } from './interface'
import './style.scss'
import LineSeparator from 'components/atoms/LineSeparator'
import CategoryIcon from 'components/atoms/CategoryIcon'

const IconBox = ({ content, icon }: IconBoxProps) => {
  return (
    <div className="icon-box">
      {icon ? (
        <img className="icon-box__icon" src={icon} alt="icon" />
      ) : (
        <div className="icon-box__icon">
          <CategoryIcon />
        </div>
      )}
      <LineSeparator color="primary" />
      <p className="icon-box__content">{content}</p>
    </div>
  )
}

export default IconBox
