import './style.scss'
import SettingsPageMenu from 'components/molecules/SettingsPageMenu'
import AccountDeletion from 'components/organisms/AccountDeletion'
import ShippingData from 'components/organisms/ShippingData'
import AccountData from 'components/organisms/AccountData'
import FiscalData from 'components/organisms/FiscalData'
import PayoutData from 'components/organisms/PayoutData'

const SettingsPage = () => {
  return (
    <div className="settings-page">
      <SettingsPageMenu />
      <AccountData />
      <ShippingData className="settings-page__shipping-data" />
      <PayoutData className="settings-page__payout-data" />
      <FiscalData className="settings-page__fiscal-data" />
      <AccountDeletion className="settings-page__account-deletion" />
    </div>
  )
}

export default SettingsPage
