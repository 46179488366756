import { ERewardType } from 'models/rewardType'
import { RewardCardImageProps } from './interface'
import './style.scss'

const RewardCardImage = ({ rewardType, image, formattedPrice }: RewardCardImageProps) => {
  const isRewardMonetary = rewardType === ERewardType.Monetary

  return (
    <div className="reward-card-image">
      {isRewardMonetary ? (
        <div className="reward-card-image__background">
          <div className="reward-card-image__background-content">
            <div className="reward-card-image__background-text">
              <h3 className="reward-card-image__background-title">{formattedPrice}</h3>
              <p className="reward-card-image__background-description">Punkty za złotówki</p>
            </div>
          </div>
        </div>
      ) : (
        <img className="reward-card-image__img" src={image} alt="Reward card image" />
      )}
    </div>
  )
}

export default RewardCardImage
