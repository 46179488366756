import Tile from 'components/atoms/Tile'
import SettingsTileHeader from 'components/molecules/SettingsTileHeader'
import { UserContext } from 'providers/user/context'
import { useContext } from 'react'
import { useModals } from 'providers/modals/context'
import SettingsTileRecord from 'components/molecules/SettingsTileRecord'
import { removeCountryCode } from 'utils/removeCountryCode'
import PhoneNumberModal from 'pages/SettingsPage/partials/modals/AccountData/PhoneNumberModal'
import FirstNameModal from 'pages/SettingsPage/partials/modals/AccountData/FirstNameModal'
import LastNameModal from 'pages/SettingsPage/partials/modals/AccountData/LastNameModal'
import ChangePasswordModal from 'pages/SettingsPage/partials/modals/AccountData/ChangePasswordModal'

const AccountData = () => {
  const { userData } = useContext(UserContext)
  const { openModal } = useModals()

  return (
    <Tile id="account-data" shadow="light">
      <SettingsTileHeader title="Dane konta" />
      <SettingsTileRecord
        className="tile__record"
        label="Imię"
        value={userData.firstName}
        button={
          !userData.firstName
            ? {
                text: 'Dodaj',
                onClick: () => openModal(<FirstNameModal action="add" />)
              }
            : {
                text: 'Zmień',
                onClick: () =>
                  openModal(<FirstNameModal action="change" defaultValue={userData.firstName} />)
              }
        }
      />
      <SettingsTileRecord
        className="tile__record"
        label="Nazwisko"
        value={userData.lastName}
        button={
          !userData.lastName
            ? {
                text: 'Dodaj',
                onClick: () => openModal(<LastNameModal action="add" />)
              }
            : {
                text: 'Zmień',
                onClick: () =>
                  openModal(<LastNameModal action="change" defaultValue={userData.lastName} />)
              }
        }
      />
      <SettingsTileRecord
        className="tile__record"
        label="Telefon"
        value={userData.phoneNumber}
        button={
          !userData.phoneNumber
            ? {
                text: 'Dodaj',
                onClick: () => openModal(<PhoneNumberModal action="add" />)
              }
            : {
                text: 'Zmień',
                onClick: () =>
                  openModal(
                    <PhoneNumberModal
                      action="change"
                      defaultValue={
                        userData.phoneNumber ? removeCountryCode(userData.phoneNumber) : undefined
                      }
                    />
                  )
              }
        }
      />
      <SettingsTileRecord className="tile__record" label="Email" value={userData.email} />
      <SettingsTileRecord
        className="tile__record"
        label="Hasło"
        value="*******"
        valueType="password"
        button={{ text: 'Zmień', onClick: () => openModal(<ChangePasswordModal />) }}
      />
    </Tile>
  )
}

export default AccountData
