import { ENDPOINTS } from 'utils/consts'
import useData from './useData'
import convert from 'color-convert'
import { ICustomColors } from 'models/customColors'

const appStyle = document.documentElement.style

const useCustomColors = () => {
  const { data: customColors, isError } = useData<ICustomColors>(ENDPOINTS.customColors)

  if (customColors) {
    const primaryColorHsl = convert.hex.hsl(customColors.primaryColor)

    appStyle.setProperty('--primary-clr-hs', `${primaryColorHsl[0]}, ${primaryColorHsl[1]}%`)
    appStyle.setProperty('--primary-clr-l', `${primaryColorHsl[2]}%`)
  } else if (isError) {
    appStyle.setProperty('--primary-clr-hs', '164, 51%')
    appStyle.setProperty('--primary-clr-l', '57%')
  }
}

export default useCustomColors
