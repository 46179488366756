import './style.scss'
import { StatusNameProps } from './interface'
import { ECandidateStatus } from 'models/candidateStatus'

const StatusName = ({ status }: StatusNameProps) => {
  const translateName = (status: ECandidateStatus): string => {
    switch (status) {
      case ECandidateStatus.NEW:
        return 'Nowy'
      case ECandidateStatus.NO_RESPONSE:
        return 'Brak odpowiedzi'
      case ECandidateStatus.CONFIRMED:
        return 'Zgoda'
      case ECandidateStatus.NON_COMPLIANT:
        return 'Brak zgody'
      case ECandidateStatus.INVITED:
        return 'Spotkanie umówione'
      case ECandidateStatus.AWAITING_MEETING:
        return 'Oczekiwanie na spotkanie'
      case ECandidateStatus.RESIGNED:
        return 'Rezygnacja przed spotkaniem'
      case ECandidateStatus.REJECTED:
        return 'Kandydat odrzucony po spotkaniu'
      case ECandidateStatus.DROPPED_OUT:
        return 'Rezygnacja klienta'
      case ECandidateStatus.HIRED_SALE:
        return 'Zatrudnienie / Sprzedaż'
    }
  }

  return <span className="status-name">{translateName(status)}</span>
}

export default StatusName
