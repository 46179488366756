import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useReadLocationStateOnce = <T>(locationStateKey: string) => {
  const location = useLocation()

  const navigationState = location.state as Record<string, T>
  const navigationValue = navigationState?.[locationStateKey]

  useEffect(() => {
    if (!navigationValue) {
      return
    }

    const { [locationStateKey]: _, ...rest } = navigationState

    window.history.replaceState(rest, document.title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationValue])

  return navigationValue
}

export default useReadLocationStateOnce
