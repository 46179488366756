import Button from 'components/atoms/Button'
import { useModals } from 'providers/modals/context'
import { showErrorAlert } from 'utils/showErrorAlert'
import ConfirmResignationModal from '../../modals/ConfirmResignationModal'
import { MeetingScheduledViewProps } from './interface'

const MeetingScheduledView = ({ meetingInfo, updateMeetingStatus }: MeetingScheduledViewProps) => {
  const { openModal } = useModals()

  const onConfirm = async () => {
    const response = await updateMeetingStatus('confirm')

    if (!response.succeed) showErrorAlert(response.errorMessage)
  }

  const onResign = () => {
    openModal(<ConfirmResignationModal rejectMeeting={() => updateMeetingStatus('reject')} />)
  }

  return (
    <>
      <h1 className="meeting-page__card-title">Potwierdź miejsce i czas spotkania</h1>
      <p className="meeting-page__card-content">
        Dziękujemy za wybranie naszej firmy i chęć spotkania się z naszym przedstawicielem. Poniżej
        znajdują się szczegóły planowanego spotkania. Jeżeli akceptujesz te warunki oraz
        potwierdzasz swoją obecność, prosimy o kliknięcie przycisku <i>Potwierdzam</i>.
        <br />
        <br />
        Jeśli masz jakiekolwiek pytania lub nie odpowiada Ci termin, prosimy o kontakt z naszym
        agentem.
      </p>
      <div className="meeting-page__card-meeting-info">
        <div className="card-meeting-info__row">
          <h4>Agent: </h4>
          <p>{meetingInfo.agentName}</p>
        </div>
        <div className="card-meeting-info__row">
          <h4>Data: </h4>
          <p>{meetingInfo.date}</p>
        </div>
        <div className="card-meeting-info__row">
          <h4>Czas: </h4>
          <p>{meetingInfo.time}</p>
        </div>
        <div className="card-meeting-info__row">
          <h4>Miejsce: </h4>
          <p>{meetingInfo.placeOfMeeting}</p>
        </div>
      </div>
      <div className="meeting-page__card-footer">
        <Button onClick={onConfirm}>Potwierdzam</Button>
        <Button className="btn--outline" onClick={onResign}>
          Rezygnuję
        </Button>
      </div>
    </>
  )
}

export default MeetingScheduledView
