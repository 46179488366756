import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import useAuth from 'hooks/useAuth'
import { valid } from 'utils/Validators/validators'
import './style.scss'
import Input from 'components/molecules/Input'
import PhoneField from 'components/molecules/PhoneField'
import AuthCard from 'components/molecules/AuthCard'
import { PATHS } from 'utils/consts'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import Checkbox from 'components/molecules/Checkbox'
import { comparePasswords } from 'utils/comparePasswords'
import { raiseFieldsErrors } from 'utils/raiseFieldsErrors'
import useRegistrationTermsAndConditions from 'pages/RegisterPage/hooks/useRegistrationTermsAndConditions'
import { MouseEvent } from 'react'

const RegisterPage = () => {
  const { isLoading, register } = useAuth()
  const methods = useForm()

  const formID = 'registerForm'
  const currentPassword = methods.watch('password')

  const { termsAndConditions } = useRegistrationTermsAndConditions()
  const showTermsAndConditions = (e: MouseEvent) => {
    // Prevent from checking/unchecking checkbox
    e.preventDefault()
    if (termsAndConditions) {
      return window.open(termsAndConditions, '_blank')
    }
  }

  const onSubmit = async (data: FieldValues) => {
    const { firstName, lastName, email, password, phoneNumber } = data

    const response = await register({ firstName, lastName, email, password, phoneNumber })

    if (!response.succeed && response.errors) {
      raiseFieldsErrors(response.errors, methods.setError)
    }
  }

  return (
    <AuthCard
      title="Rejestracja"
      switchBox={{ text: 'Masz już konto?', linkText: 'Zaloguj się', link: PATHS.login }}
      submitButton={{ text: 'Zarejestruj', formID, disabled: isLoading }}>
      <FormProvider {...methods}>
        <form className="register-form" id={formID} onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="register-form__fields-wrapper">
            <Input
              name="firstName"
              placeholder="Imię"
              validators={{ required: valid.required, ...validSchemas.name }}
            />

            <Input
              name="lastName"
              placeholder="Nazwisko"
              validators={{ required: valid.required, ...validSchemas.name }}
            />
          </div>

          <Input
            name="email"
            placeholder="Email"
            validators={{ required: valid.required, pattern: valid.emailPattern }}
          />

          <PhoneField required />

          <div className="register-form__fields-wrapper">
            <Input
              name="password"
              type="password"
              placeholder="Hasło"
              validators={{ required: valid.required, pattern: valid.passwordPattern }}
            />

            <Input
              name="confirmPassword"
              type="password"
              placeholder="Powtórz hasło"
              validators={{
                required: valid.required,
                validate: (value) => comparePasswords(currentPassword, value)
              }}
            />
          </div>

          <Checkbox
            className="register-form__checkbox"
            name="termsAcceptance"
            validators={{ required: valid.required }}>
            {'Potwierdzam, że zapoznałem/zapoznałam się z '}
            <span className="checkbox__terms-link" onClick={showTermsAndConditions}>
              regulaminem
            </span>
            {' i akceptację jego treści.'}
          </Checkbox>
        </form>
      </FormProvider>
    </AuthCard>
  )
}

export default RegisterPage
