import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'
import { IHomePageSummary } from '../models/homePageSummary'

const useHomePageSummary = () => {
  const { data } = useData<IHomePageSummary>(ENDPOINTS.homePageSummary)

  return { data }
}

export default useHomePageSummary
