import Button from 'components/atoms/Button'
import { MouseEvent } from 'react'
import { RewardCardContentProps } from './interface'
import './style.scss'

const RewardCardContent = ({
  title,
  shortDescription,
  formattedPrice,
  buttonType,
  disableButton,
  onButtonClick
}: RewardCardContentProps) => {
  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onButtonClick()
  }

  return (
    <div className="reward-card-content">
      <h3 className="reward-card-content__title">{title}</h3>
      <p className="reward-card-content__description">{shortDescription}</p>
      <div className="reward-card-content__footer">
        <p className="reward-card-content__cost">{formattedPrice}</p>
        {buttonType === 'purchase' ? (
          <Button
            className="reward-card-content__purchase-btn"
            onClick={handleButtonClick}
            disable={disableButton}>
            Aktywuj
          </Button>
        ) : (
          <button className="reward-card-content__details-btn" onClick={handleButtonClick}>
            Zobacz więcej
          </button>
        )}
      </div>
    </div>
  )
}

export default RewardCardContent
