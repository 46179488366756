import { IPurchaseReward } from 'models/requests/purchaseReward'
import { ENDPOINTS } from 'utils/consts'
import useData from './useData'

const usePurchaseReward = () => {
  const { createData: purchaseReward } = useData<IPurchaseReward>(ENDPOINTS.purchaseReward, {
    dontFetchOnMount: true
  })

  return { purchaseReward }
}

export default usePurchaseReward
