import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Button from 'components/atoms/Button'
import LeadCategoryList from 'components/molecules/LeadCategoryList'
import './style.scss'
import { CandidateLeadsProps } from './interface'
import Controller from 'components/atoms/Controller'
import classNames from 'classnames'

const CandidateLeads = ({
  leadCategoriesData,
  nextFormStep,
  className,
  buttonType
}: CandidateLeadsProps) => {
  const { watch, getValues } = useFormContext()

  const [isFormInvalid, setIsFormInvalid] = useState(true)
  const leadCategoriesField: number[] = watch('leadCategories')

  const checkForm = () => {
    if (leadCategoriesField?.length < 1 || leadCategoriesField?.length > 4) {
      setIsFormInvalid(true)
    } else {
      setIsFormInvalid(false)
    }
  }

  useEffect(() => {
    checkForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadCategoriesField])

  return (
    <div className={classNames('candidate-leads', className)}>
      <Controller
        name="leadCategories"
        Element={LeadCategoryList}
        elementProps={{
          leadCategories: leadCategoriesData,
          defaultValue: getValues('leadCategories')
        }}
      />

      <Button
        disable={isFormInvalid}
        className="candidate-leads__btn"
        type={buttonType}
        onClick={nextFormStep}>
        {`Dalej (${leadCategoriesField ? leadCategoriesField.length : 0})`}
      </Button>
    </div>
  )
}

export default CandidateLeads
