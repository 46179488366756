import classNames from 'classnames'
import { SettingsTileRecordProps } from './interface'
import './style.scss'

const SettingsTileRecord = ({
  label,
  value,
  valueType,
  secondValue,
  button,
  className
}: SettingsTileRecordProps) => {
  const renderValue = () => {
    if (!value) {
      return <span>-</span>
    } else if (typeof value === 'string') {
      return <span>{value}</span>
    } else {
      return (
        <>
          <span>{value.fullName}</span>
          <span>{value.phoneNumber}</span>
          {value.secondaryAddressLine ? (
            <span>{`${value.firstAddressLine}, ${value.secondaryAddressLine}`}</span>
          ) : (
            <span>{value.firstAddressLine}</span>
          )}
          <span>{`${value.postCode} ${value.city}`}</span>
          <span>{`Woj. ${value.voivodeship}`}</span>
        </>
      )
    }
  }

  return (
    <div className={classNames('settings-tile-record', className)}>
      <label className="settings-tile-record__label">{label}</label>
      <div
        className={classNames('settings-tile-record__value', {
          'settings-tile-record__value--bold': secondValue,
          'settings-tile-record__value--password': valueType === 'password'
        })}>
        {renderValue()}
      </div>

      {secondValue && (
        <span className="settings-tile-record__value settings-tile-record__value--second">
          {secondValue}
        </span>
      )}

      {button && (
        <span className="settings-tile-record__btn" onClick={button.onClick}>
          {button.text}
        </span>
      )}
    </div>
  )
}

export default SettingsTileRecord
