import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'

const useCustomAuthBgImg = () => {
  const { data: authBgImg } = useData<string>(ENDPOINTS.customAuthBgImg, {
    dataLocation: 'loginBackground'
  })

  return { authBgImg }
}

export default useCustomAuthBgImg
