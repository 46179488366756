import useData from 'hooks/useData'
import { IFormattedArticlesResponse } from 'models/responses/formattedArticlesResponse'
import { ENDPOINTS } from 'utils/consts'
import { transformArticlesResponseData } from 'utils/TransformResponseData/articles'

const useArticles = () => {
  const { data } = useData<IFormattedArticlesResponse>(ENDPOINTS.articles, {
    transformResponseData: transformArticlesResponseData
  })

  const { numberOfArticles, highlitedArticle, restOfArticles } = data || {}

  return { numberOfArticles, highlitedArticle, restOfArticles }
}

export default useArticles
