import ArticleCardItem from '../ArticleCardItem'
import { ArticleCardListProps } from './interface'

const ArticleCardList = ({ articles }: ArticleCardListProps) => {
  return (
    <div>
      {articles.map((article, index) => (
        <ArticleCardItem
          key={article.id}
          variant={index % 2 === 0 ? 'imgRight' : 'imgLeft'}
          article={article}
        />
      ))}
    </div>
  )
}

export default ArticleCardList
