import Tile from 'components/atoms/Tile'
import './style.scss'

const SettingsPageMenu = () => {
  return (
    <Tile className="settings-page-menu" shadow="light">
      <ul className="settings-page-menu__list">
        <li className="settings-page-menu__item">
          <a className="settings-page-menu__link" href="#account-data">
            Dane konta
          </a>
        </li>
        <li className="settings-page-menu__item">
          <a className="settings-page-menu__link" href="#shipping-data">
            Dane do wysyłki
          </a>
        </li>
        <li className="settings-page-menu__item">
          <a className="settings-page-menu__link" href="#payout-data">
            Dane do wypłaty
          </a>
        </li>
        <li className="settings-page-menu__item">
          <a className="settings-page-menu__link" href="#fiscal-data">
            Dane podatkowe
          </a>
        </li>
        <li className="settings-page-menu__item">
          <a className="settings-page-menu__link" href="#account-deletion">
            Usuwanie konta
          </a>
        </li>
      </ul>
    </Tile>
  )
}

export default SettingsPageMenu
