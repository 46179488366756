import classNames from 'classnames'
import Tile from 'components/atoms/Tile'
import SettingsTileHeader from 'components/molecules/SettingsTileHeader'
import SettingsTileRecord from 'components/molecules/SettingsTileRecord'
import useBankAccounts from 'hooks/usePayoutData'
import AddPayoutDataForm from 'pages/SettingsPage/partials/forms/AddPayoutDataForm'
import ChangePayoutDataModal from 'components/templates/ChangePayoutDataModal'
import { useModals } from 'providers/modals/context'
import { useState } from 'react'
import { PayoutDataProps } from './interface'

const PayoutData = ({ className }: PayoutDataProps) => {
  const { mainPayoutData, createPayoutData, updatePayoutData } = useBankAccounts()
  const { openModal } = useModals()

  const [showForm, setShowForm] = useState(false)

  return (
    <Tile id="payout-data" className={classNames(className)} shadow="light">
      <SettingsTileHeader
        title="Dane do wypłaty"
        button={
          !mainPayoutData && !showForm
            ? { text: 'Dodaj', onClick: () => setShowForm(true) }
            : undefined
        }
      />

      {mainPayoutData ? (
        <SettingsTileRecord
          className="tile__record"
          label="Numer konta"
          value={mainPayoutData?.bankNumber}
          button={{
            text: 'Zmień',
            onClick: () =>
              openModal(
                <ChangePayoutDataModal
                  payoutData={mainPayoutData}
                  updatePayoutData={updatePayoutData}
                />
              )
          }}
        />
      ) : (
        showForm && (
          <AddPayoutDataForm
            createPayoutData={createPayoutData}
            closeForm={() => setShowForm(false)}
          />
        )
      )}
    </Tile>
  )
}

export default PayoutData
