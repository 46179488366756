import { useEffect, useState } from 'react'

const useWindowMatchMedia = (breakpoint: number, constraint: 'max' | 'min') => {
  const mediaQuery = `(${constraint}-width: ${breakpoint}px)`
  const [doesMatch, setDoesMatch] = useState(window.matchMedia(mediaQuery).matches)

  const handleViewportWidthChange = (e: MediaQueryListEvent) => setDoesMatch(e.matches)

  useEffect(() => {
    window.matchMedia(mediaQuery).addEventListener('change', handleViewportWidthChange)

    return () =>
      window.matchMedia(mediaQuery).removeEventListener('change', handleViewportWidthChange)
  }, [mediaQuery])

  return [doesMatch]
}

export default useWindowMatchMedia
