import WithdrawModal from 'components/organisms/WithdrawModal'
import { useModals } from 'providers/modals/context'
import { RewardCardProps } from './interface'
import { useContext } from 'react'
import { UserContext } from 'providers/user/context'
import useRewardModalDetails from 'hooks/useRewardModalDetails'
import RewardDetailsModal from 'components/organisms/RewardDetailsModal'
import RewardCardImage from '../RewardCardImage'
import RewardCardContent from '../RewardCardContent'
import RewardCardTile from 'components/atoms/RewardCardTile'

const RewardCard = ({
  id,
  title,
  shortDescription,
  cost,
  type,
  payout,
  image
}: RewardCardProps) => {
  const { getData } = useRewardModalDetails(id, { dontFetchOnMount: true })
  const { openModal } = useModals()
  const { userPoints } = useContext(UserContext)

  const disableButton = (userPoints || 0) < cost
  const formattedPrice = payout ? `${cost} pkt / ${payout} zł` : `${cost} pkt`

  const openDetailsModal = async () => {
    const response = await getData()

    if (response.succeed && response.data) {
      openModal(<RewardDetailsModal data={response.data} />)
    }
  }

  const openWithdrawModal = () => {
    openModal(
      <WithdrawModal
        rewardID={id}
        rewardType={type}
        rewardName={title}
        rewardFormattedPrice={formattedPrice}
        rewardCost={cost}
      />
    )
  }

  return (
    <RewardCardTile onCardClick={openDetailsModal}>
      <RewardCardImage rewardType={type} image={image} formattedPrice={formattedPrice} />
      <RewardCardContent
        title={title}
        shortDescription={shortDescription}
        formattedPrice={formattedPrice}
        buttonType="purchase"
        disableButton={disableButton}
        onButtonClick={openWithdrawModal}
      />
    </RewardCardTile>
  )
}

export default RewardCard
