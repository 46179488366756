import classNames from 'classnames'
import { valid } from 'utils/Validators/validators'
import Input from '../Input'
import { PhoneFieldProps } from './interface'
import './style.scss'

const PhoneField = ({ required, defaultValue, className }: PhoneFieldProps) => {
  return (
    <div className={classNames('phone-field', className)}>
      <span className="phone-field__prefix">+48</span>
      <Input
        className="phone-field__input"
        name="phoneNumber"
        placeholder="Telefon"
        defaultValue={defaultValue}
        validators={{ required: required ? valid.required : false, pattern: valid.phonePattern }}
      />
    </div>
  )
}

export default PhoneField
