import { TModalActions } from 'models/modalActions'

export const getModalButtonText = (action: TModalActions): string => {
  switch (action) {
    case 'add':
      return 'Dodaj'
    case 'change':
      return 'Zapisz'
  }
}
