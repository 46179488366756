import Button from 'components/atoms/Button'
import { Link } from 'react-router-dom'
import { PATHS } from 'utils/consts'

const MeetingCanceledView = () => {
  return (
    <>
      <h1 className="meeting-page__card-title">Dziękujemy</h1>
      <p className="meeting-page__card-content meeting-page__card-content--light-color">
        Twój link wygasł. Jeżeli chcesz umówić spotkanie z naszym agentem, prosimy o kontakt.
        Dziękujemy za zainteresowanie naszym programem.
      </p>
      <div className="meeting-page__card-footer">
        <Link to={PATHS.home}>
          <Button className="meeting-page__card-btn btn--outline">Strona główna</Button>
        </Link>
      </div>
    </>
  )
}

export default MeetingCanceledView
