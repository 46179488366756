import SidewayModal from 'components/molecules/SidewayModal'
import { useModals } from 'providers/modals/context'
import { PurchaseDetailsModalProps } from './interface'
import { format } from 'date-fns'
import { pl } from 'date-fns/locale'
import parse from 'html-react-parser'

const PurchaseDetailsModal = ({
  purchasedItem,
  rewardFormattedPrice
}: PurchaseDetailsModalProps) => {
  const { closeModal } = useModals()

  const {
    reward: { title: rewardName },
    purchaseDate,
    bankAccount,
    shippingAddress
  } = purchasedItem
  const formattedPurchaseDate = format(new Date(purchaseDate), 'd MMMM yyyy', { locale: pl })
  const formattedShippingAddress = shippingAddress?.replaceAll('\n', '<br />')

  return (
    <SidewayModal
      title="Szczegóły zakupu"
      productInfo={{ name: rewardName, price: rewardFormattedPrice }}
      footer={{
        title: 'Data aktywacji nagrody:',
        content: formattedPurchaseDate
      }}
      button={{ text: 'Zamknij', onClick: closeModal }}>
      {bankAccount && (
        <li className="sideway-modal__process-step">
          <h4 className="process-step__title">Dane do wypłaty</h4>
          {bankAccount}
        </li>
      )}
      {formattedShippingAddress && (
        <li className="sideway-modal__process-step">
          <h4 className="process-step__title">Dane do wysyłki</h4>
          {parse(formattedShippingAddress)}
        </li>
      )}
    </SidewayModal>
  )
}

export default PurchaseDetailsModal
