import Input from 'components/molecules/Input'
import Modal from 'components/organisms/Modal'
import { IPayoutData } from 'models/payoutData'
import { useModals } from 'providers/modals/context'
import { FormProvider, useForm } from 'react-hook-form'
import { handleApiResponse } from 'utils/handleApiResponse'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import { AddPayoutDataModalProps } from './interface'

const AddPayoutDataModal = ({ createPayoutData }: AddPayoutDataModalProps) => {
  const { closeModal } = useModals()
  const methods = useForm<Omit<IPayoutData, 'id'>>()

  const formID = 'addPayoutDataModal'

  const onSubmit = async (formValues: Omit<IPayoutData, 'id'>) => {
    const response = await createPayoutData(formValues)

    handleApiResponse(response, closeModal, methods.setError)
  }

  return (
    <Modal title="Dodaj dane do wypłaty" buttonText="Dodaj" formID={formID}>
      <FormProvider {...methods}>
        <form id={formID} className="modal__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            name="bankNumber"
            placeholder="Numer rachunku"
            validators={validSchemas.bankNumber}
          />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default AddPayoutDataModal
