import classNames from 'classnames'
import { ArticleTypeTagProps } from './interface'
import './style.scss'

const ArticleTypeTag = ({ children, orientation, className }: ArticleTypeTagProps) => {
  return (
    <div
      className={classNames('article-type-tag', className, {
        'article-type-tag--top': orientation === 'top',
        'article-type-tag--left': orientation === 'left',
        'article-type-tag--right': orientation === 'right'
      })}>
      <p className="article-type-tag__text">{children}</p>
    </div>
  )
}

export default ArticleTypeTag
