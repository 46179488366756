import { ICandidate } from 'models/candidate'
import { ICreateCandidate } from 'models/requests/createCandidate'
import { ENDPOINTS, RECORDS_PER_PAGE } from 'utils/consts'
import useData from './useData'

const useCandidates = () => {
  const {
    data: candidates,
    pageCount,
    isError,
    getData: getCandidates,
    createData: createCandidate,
    ...rest
  } = useData<ICandidate[], ICandidate[], ICreateCandidate>(ENDPOINTS.candidates, {
    dataLocation: 'results',
    recordsPerPage: RECORDS_PER_PAGE.candidates
  })

  return { candidates, pageCount, isError, getCandidates, createCandidate, ...rest }
}

export default useCandidates
