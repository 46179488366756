import classNames from 'classnames'
import { RewardDetailsNavProps } from '../interface'

export const PrevButton = ({ enabled, onClick }: RewardDetailsNavProps) => (
  <button
    className={classNames(
      'reward-details-modal__nav-button reward-details-modal__nav-button--prev',
      {
        'reward-details-modal__nav-button--disabled': !enabled
      }
    )}
    onClick={onClick}>
    <svg width="9" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8a1 1 0 0 1 .29-.71l6-6a1.004 1.004 0 1 1 1.42 1.42L2.41 8l5.29 5.29a1 1 0 0 1-1.41 1.41l-6-6A1 1 0 0 1 0 8Z" />
    </svg>
  </button>
)

export const NextButton = ({ enabled, onClick }: RewardDetailsNavProps) => (
  <button
    className={classNames(
      'reward-details-modal__nav-button reward-details-modal__nav-button--next',
      {
        'reward-details-modal__nav-button--disabled': !enabled
      }
    )}
    onClick={onClick}>
    <svg width="9" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 7a1 1 0 0 1-.29.71l-6 6a1.004 1.004 0 0 1-1.42-1.42L6.59 7 1.3 1.71A1 1 0 0 1 2.71.3l6 6A1 1 0 0 1 9 7Z" />
    </svg>
  </button>
)
