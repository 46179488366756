import Modal from 'components/organisms/Modal'
import { useModals } from 'providers/modals/context'
import { showErrorAlert } from 'utils/showErrorAlert'
import { ConfirmResignationModalProps } from './interface'

const ConfirmResignationModal = ({ rejectMeeting }: ConfirmResignationModalProps) => {
  const { closeModal } = useModals()

  const onSubmit = async () => {
    const response = await rejectMeeting()

    if (response.succeed) closeModal()
    else showErrorAlert(response.errorMessage)
  }

  return (
    <Modal title="Rezygnacja z programu" buttonText="Potwierdź" onSubmit={onSubmit}>
      <p>
        Czy na pewno chcesz zrezygnować z udziału w programie LeadIn? Ta operacja jest{' '}
        <strong>nieodwracalna</strong>.
      </p>
      <br />
      <p>
        Jeżeli nie odpowiada Ci termin spotkania, skontaktuj się <br /> z przedstawicielem.
      </p>
    </Modal>
  )
}

export default ConfirmResignationModal
