import './style.scss'
import { useState } from 'react'
import CandidateInfo from 'components/organisms/CandidateInfo'
import CandidateLeads from 'components/organisms/CandidateLeads'
import FormCard from 'components/organisms/FormCard'
import CandidateAdded from 'components/organisms/CandidateAdded'
import Dashboard from 'components/organisms/Dashboard'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { raiseFieldsErrors } from 'utils/raiseFieldsErrors'
import useCandidates from 'hooks/useCandidates'
import useLeadCategories from 'hooks/useLeadCategories'
import ContentSwitcherButton from 'components/atoms/ContentSwitcherButton'
import { EProfilePageContentType } from './models/profilePageContentType'
import PurchaseHistory from 'components/organisms/PurchaseHistory'
import useCustomProfileBgImg from './hook/useCustomProfileBgImg'

const ProfilePage = () => {
  const { leadCategories } = useLeadCategories()
  const { profileBgImg } = useCustomProfileBgImg()
  const { candidates, pageCount, isError, getCandidates, createCandidate } = useCandidates()
  const methods = useForm()

  const [formStep, setFormStep] = useState(0)
  const [visibleContent, setVisibleContent] = useState(EProfilePageContentType.Recommendations)

  const nextFormStep = () => setFormStep((currentStep) => currentStep + 1)
  const prevFormStep = () => setFormStep((currentStep) => currentStep - 1)
  const resetFormSteps = () => setFormStep(0)

  const onSubmit = async (formValues: FieldValues) => {
    const { firstName, phoneNumber, leadCategories, region } = formValues

    const response = await createCandidate({
      firstName,
      phoneNumber,
      leadCategories,
      region
    })

    if (response.succeed) {
      nextFormStep()
    } else if (!response.succeed && response.errors) {
      raiseFieldsErrors(response.errors, methods.setError)
    }
  }

  return (
    <div className="profile-page">
      <section className="profile__recommendation">
        <img
          src={profileBgImg || require('assets/images/default-profile-bg-img.png')}
          className="recommendation__bg"
        />
        <FormCard
          className="recommendation__card"
          formStep={formStep}
          prevFormStep={prevFormStep}
          leadCategoriesLength={leadCategories?.length || 0}>
          <FormProvider {...methods}>
            <form
              className="card__form"
              id="recommendationForm"
              onSubmit={methods.handleSubmit(onSubmit)}>
              {formStep === 0 && (
                <CandidateLeads
                  nextFormStep={nextFormStep}
                  leadCategoriesData={leadCategories || []}
                />
              )}
              {formStep === 1 && <CandidateInfo />}
              {formStep === 2 && <CandidateAdded resetFormSteps={resetFormSteps} />}
            </form>
          </FormProvider>
        </FormCard>
      </section>
      <section className="profile__info">
        <div className="profile__menu">
          <ContentSwitcherButton
            onClick={() => setVisibleContent(EProfilePageContentType.Recommendations)}
            isActive={visibleContent === EProfilePageContentType.Recommendations}>
            Twoje polecenia
          </ContentSwitcherButton>
          <ContentSwitcherButton
            onClick={() => setVisibleContent(EProfilePageContentType.PurchaseHistory)}
            isActive={visibleContent === EProfilePageContentType.PurchaseHistory}>
            Kupione nagrody
          </ContentSwitcherButton>
        </div>
        {visibleContent === EProfilePageContentType.Recommendations ? (
          <Dashboard
            candidates={candidates}
            pageCount={pageCount}
            isError={isError}
            getCandidates={getCandidates}
          />
        ) : (
          <PurchaseHistory />
        )}
      </section>
    </div>
  )
}

export default ProfilePage
