import { Link } from 'react-router-dom'
import useMessageAfterInviteRejection from './hooks/useMessageAfterInviteRejection'
import { InviteFinishProps } from './interface'
import './style.scss'

const InviteFinish = ({ isCanceled, isRejected }: InviteFinishProps) => {
  const { messageAfterInviteRejection } = useMessageAfterInviteRejection()

  const title = isCanceled || isRejected ? 'Dziękujemy' : 'Gotowe!'

  return (
    <div className="invite-finish">
      <h1 className={'invite-finish__title'}>{title}</h1>
      <p className="invite-finish__description">
        {!isCanceled && !isRejected && (
          <>
            Dziękujemy za chęć wzięcia udziału w programie. Agent wybranej przez Ciebie placówki
            skontaktuje się z Tobą niebawem.
          </>
        )}
        {isRejected && (
          <>
            Twój link wygasł albo nie została udzielona zgoda na dalszy udział w programie, przez co
            Twoje dane zostały usunięte. Zachęcamy do zapoznania się z ofertą naszego programu.
          </>
        )}
        {isCanceled && messageAfterInviteRejection}
      </p>

      <img
        src={require('assets/images/invite-finish.png')}
        alt="Ikona"
        className="invite-finish__img"
      />
      <div className="invite-finish__action">
        <Link className="invite-finish__btn btn btn--outline" to="/">
          Strona główna
        </Link>
      </div>
    </div>
  )
}

export default InviteFinish
