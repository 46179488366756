import { useNavigate } from 'react-router-dom'

const useRouteChange = () => {
  const navigate = useNavigate()

  const changeRoute = (link: string) => {
    if (link.startsWith('http')) {
      window.location.href = link
    } else {
      navigate(link)
    }
  }

  return { changeRoute }
}

export default useRouteChange
