import Tile from 'components/atoms/Tile'
import SettingsTileHeader from 'components/molecules/SettingsTileHeader'
import SettingsTileRecord from 'components/molecules/SettingsTileRecord'
import useShippingData from 'hooks/useShippingData'
import AddShippingDataForm from 'pages/SettingsPage/partials/forms/AddShippingDataForm'
import ChangeShippingDataModal from 'components/templates/ChangeShippingDataModal'
import { useModals } from 'providers/modals/context'
import { useState } from 'react'
import { getVoivodeship } from 'utils/getVoivodeship'
import { ShippingDataProps } from './interface'

const ShippingData = ({ className }: ShippingDataProps) => {
  const { mainShippingData, createShippingData, updateShippingData } = useShippingData()
  const { openModal } = useModals()

  const [showForm, setShowForm] = useState(false)

  return (
    <Tile id="shipping-data" className={className} shadow="light">
      <SettingsTileHeader
        title="Dane do wysyłki"
        button={
          !mainShippingData && !showForm
            ? { text: 'Dodaj', onClick: () => setShowForm(true) }
            : undefined
        }
      />

      {mainShippingData ? (
        <SettingsTileRecord
          label="Główne"
          value={{
            fullName: mainShippingData.fullName,
            phoneNumber: mainShippingData.phoneNumber,
            firstAddressLine: mainShippingData.firstAddressLine,
            secondaryAddressLine: mainShippingData.secondaryAddressLine,
            city: mainShippingData.city,
            postCode: mainShippingData.postCode,
            voivodeship: getVoivodeship(mainShippingData.voivodeship)?.label || ''
          }}
          button={{
            text: 'Zmień',
            onClick: () =>
              openModal(
                <ChangeShippingDataModal
                  shippingData={mainShippingData}
                  updateShippingData={updateShippingData}
                />
              )
          }}
        />
      ) : (
        <>
          {showForm && (
            <AddShippingDataForm
              createShippingData={createShippingData}
              closeForm={() => setShowForm(false)}
            />
          )}
        </>
      )}
    </Tile>
  )
}

export default ShippingData
