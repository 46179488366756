import { LeadCategoryItemProps } from './interface'
import CategoryIcon from 'assets/icons/category.svg'
import classNames from 'classnames'
import './style.scss'

const LeadCategoryItem = ({ leadCategory, onClick, isSelected }: LeadCategoryItemProps) => {
  return (
    <li
      className={classNames('lead-category', { selected: isSelected })}
      onClick={() => onClick(leadCategory.id)}>
      <img className="lead-category__icon" src={leadCategory.icon || CategoryIcon} />
      <p className="lead-category__content">{leadCategory.category}</p>
    </li>
  )
}

export default LeadCategoryItem
