import ReactGa from 'react-ga4'
import axiosAuth from 'setup/axios/authInstance'
import { ENDPOINTS } from './consts'

export const initializeGoogleAnalytics = async () => {
  const { data } = await axiosAuth.get(ENDPOINTS.googleAnalytics)

  const googleAnalyticsId = data.gaIdentificator

  if (!googleAnalyticsId) {
    return
  }

  ReactGa.initialize(googleAnalyticsId)
}
