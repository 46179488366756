import { IApiPaginatedListResponse } from 'models/responses/apiPaginatedListReponse'
import { IReward } from 'models/reward'
import { useRewardShopFiltersContext } from 'providers/rewardShopFilters/context'
import { ENDPOINTS, RECORDS_PER_PAGE } from 'utils/consts'
import useData from './useData'

const useRewards = () => {
  const { params } = useRewardShopFiltersContext()

  const {
    data,
    getData: getRewards,
    ...rest
  } = useData<IApiPaginatedListResponse<IReward>>(`${ENDPOINTS.rewards}?${params}`, {
    recordsPerPage: RECORDS_PER_PAGE.rewards
  })

  const { count: rewardsCount, results: rewardsList = [] } = data || {}

  return { rewardsList, rewardsCount, getRewards, ...rest }
}

export default useRewards
