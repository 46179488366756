import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'

const useMessageAfterInviteRejection = () => {
  const { data: messageAfterInviteRejection } = useData<string>(
    ENDPOINTS.messageAfterInviteRejection,
    {
      dataLocation: 'prompt2'
    }
  )

  return { messageAfterInviteRejection }
}

export default useMessageAfterInviteRejection
