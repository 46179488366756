import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'

const useRecommendationTermsAndConditions = () => {
  const { data: termsAndConditions } = useData<string>(ENDPOINTS.recommendationTermsAndConditions, {
    dataLocation: 'recommendationTermsAndConditions'
  })

  return { termsAndConditions }
}

export default useRecommendationTermsAndConditions
