import Button from 'components/atoms/Button'
import { InviteTermsProps } from './interface'
import './style.scss'
import { useFormContext } from 'react-hook-form'
import { useProgramInfo } from 'pages/InvitePage/hooks/useProgramInfo'

const InviteTerms = ({ onNextStepClick, onCancelClick }: InviteTermsProps) => {
  const { setValue } = useFormContext()
  const { programInfo } = useProgramInfo()

  const handleNextStepBtnClick = () => {
    setValue('agreementToTermsAndConditions', true)
    onNextStepClick(['agreementToTermsAndConditions'])
  }

  return (
    <div className="invite-terms">
      <div className="invite-terms__content-wrapper">
        <h1 className="invite-terms__title">Zgoda na wzięcie udziału w programie</h1>
        <p className="invite-terms__description">{programInfo}</p>
      </div>

      <div className="invite-terms__actions">
        <Button type="button" form="inviteForm" onClick={handleNextStepBtnClick}>
          Dalej
        </Button>
        <button type="button" className="invite-terms__resign-btn" onClick={onCancelClick}>
          Rezygnuję
        </button>
      </div>
    </div>
  )
}

export default InviteTerms
