import { ArticleDatesBoxProps } from './interface'
import './style.scss'

const ArticleDatesBox = ({ creationDate, updateDate }: ArticleDatesBoxProps) => {
  return (
    <div className="article-dates-box">
      <p className="article-dates-box__date">{creationDate}</p>
      <span className="article-dates-box__divider" />
      <p className="article-dates-box__date">{`Edytowano: ${updateDate}`}</p>
    </div>
  )
}

export default ArticleDatesBox
