import { IMeeting } from 'models/meeting'
import { useCallback, useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { ENDPOINTS, LOCAL_STORAGE } from 'utils/consts'
import useData from './useData'
import { format } from 'date-fns'
import { IServerResponse } from 'models/serverResponse'
import axiosDefault from 'setup/axios/defaultInstance'
import { parseApiErrors } from 'utils/parseApiErrors'
import { IFormattedMeeting } from 'models/formattedMeeting'

const useMeeting = () => {
  const candidateHash = localStorage.getItem(LOCAL_STORAGE.candidateHash)
  const endpoint = generatePath(ENDPOINTS.meeting, { hash: candidateHash || undefined })

  const { data: meetingRawData, setData: setMeetingRawData } = useData<IMeeting>(endpoint)

  const meetingInfo: IFormattedMeeting | undefined = useMemo(() => {
    if (!meetingRawData) return undefined

    const { meetingDate, agent, meetingRegion, ...rest } = meetingRawData

    const formattedDate = new Date(meetingDate)
    const date = format(formattedDate, 'dd.MM.yyyy')
    const time = format(formattedDate, 'H:mm')

    const agentName = [agent.firstName, agent.lastName].join(' ')
    const placeOfMeeting = meetingRegion.name

    return { date, time, agentName, placeOfMeeting, ...rest }
  }, [meetingRawData])

  const updateMeetingStatus = useCallback(
    async (status: 'confirm' | 'reject'): Promise<IServerResponse> => {
      try {
        const response = await axiosDefault.patch(endpoint + `${status}/`)

        setMeetingRawData(response.data)

        return { succeed: true }
      } catch (error) {
        return parseApiErrors(error)
      }
    },
    [endpoint, setMeetingRawData]
  )

  return { meetingInfo, updateMeetingStatus }
}

export default useMeeting
