import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { FaArrowLeft } from 'react-icons/fa'
import './style.scss'
import { FormProvider, useForm } from 'react-hook-form'
import InviteAddress from 'components/molecules/InviteAddress'
import InviteCategories from 'components/molecules/InviteCategories'
import InviteTerms from 'components/molecules/InviteTerms'
import InviteDate from 'components/molecules/InviteDate'
import InviteFinish from 'components/molecules/InviteFinish'
import useCandidateSurvey from 'hooks/useCandidateSurvey'
import filterObjectKeys from 'utils/filterObjectKeys'
import useUserStatus from 'hooks/useUserStatus'
import { InviteFormData } from './interface'
import { setCandidateDetails } from 'utils/setCandidateDetails'

const InvitePage = () => {
  const { formatedData, userData, errorMessage, sendData, reject } = useCandidateSurvey()
  const { rejected } = useUserStatus()
  const methods = useForm<InviteFormData>()

  const [formStep, setFormStep] = useState(0)
  const [canceled, setCanceled] = useState(false)
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)

  const formID = 'inviteForm'
  const shouldShowArrow = !canceled && !isSubmitSuccessful && formStep !== 0 && formStep !== 1

  const handleReject = () => {
    setCanceled(true)
    reject()
  }

  const handleNextStep = async (inputs: string[]) => {
    try {
      const formValues = methods.getValues()
      const currentStepValue = filterObjectKeys(formValues, inputs)
      const res = await sendData(currentStepValue)

      if (res.succeed) {
        setFormStep(formStep + 1)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (formatedData) {
      setCandidateDetails(formatedData, methods)
    }

    if (errorMessage) {
      const errorMessages = Object.entries(errorMessage)
      errorMessages.forEach(([key, value]) =>
        methods.setError(key as keyof InviteFormData, value[0])
      )
    }
  }, [formatedData, userData, methods, errorMessage])

  return (
    <section className="invite-page">
      <img src={require('assets/images/default-invite-bg-img.png')} className="invite-page__bg" />
      <div className="invite-page__card">
        {shouldShowArrow && (
          <button className="invite-page__card-back" onClick={() => setFormStep(formStep - 1)}>
            <FaArrowLeft />
          </button>
        )}
        {!canceled && !isSubmitSuccessful && !rejected && (
          <div className="invite-page__card-progress">
            <span
              className={classNames('invite-page__card-eclipse', {
                'invite-page__card-eclipse--active': formStep === 0
              })}
            />
            <span
              className={classNames('invite-page__card-eclipse', {
                'invite-page__card-eclipse--active': formStep === 1
              })}
            />
            <span
              className={classNames('invite-page__card-eclipse', {
                'invite-page__card-eclipse--active': formStep === 2
              })}
            />
            <span
              className={classNames('invite-page__card-eclipse', {
                'invite-page__card-eclipse--active': formStep === 3
              })}
            />
          </div>
        )}
        <FormProvider {...methods}>
          <form className="invite-form" id={formID}>
            {formStep === 0 && !canceled && !rejected && (
              <InviteTerms onNextStepClick={handleNextStep} onCancelClick={handleReject} />
            )}
            {formStep === 1 && <InviteAddress onNextStepClick={handleNextStep} />}
            {formStep === 2 && <InviteCategories onNextStepClick={handleNextStep} />}
            {formStep === 3 && !isSubmitSuccessful && <InviteDate {...{ setIsSubmitSuccessful }} />}
            {isSubmitSuccessful && <InviteFinish />}
            {canceled && <InviteFinish isCanceled />}
            {rejected && <InviteFinish isRejected />}
          </form>
        </FormProvider>
      </div>
    </section>
  )
}

export default InvitePage
