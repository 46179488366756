import {
  faFile,
  faFileAudio,
  faFileCsv,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFileVideo,
  faFileZipper,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const renderIconBasedOnFileExtension = (extension: string) => {
  let icon: IconDefinition = faFile

  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'webp':
    case 'svg':
      icon = faFileImage
      break
    case 'mp4':
    case 'mpeg':
    case 'mov':
    case 'webm':
      icon = faFileVideo
      break
    case 'mp3':
      icon = faFileAudio
      break
    case 'txt':
    case 'docx':
    case 'doc':
      icon = faFileLines
      break
    case 'pdf':
      icon = faFilePdf
      break
    case 'zip':
      icon = faFileZipper
      break
    case 'csv':
      icon = faFileCsv
      break
  }

  return <FontAwesomeIcon icon={icon} />
}
