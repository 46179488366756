import { Controller as ReactController, useFormContext } from 'react-hook-form'
import { ControllerProps } from './interface'

const Controller = ({ name, rules, Element, elementProps, defaultValue }: ControllerProps<any>) => {
  const { control } = useFormContext()

  return (
    <ReactController
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Element {...elementProps} onChange={onChange} error={error} />
      )}
    />
  )
}

export default Controller
