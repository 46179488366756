import { useModals } from 'providers/modals/context'
import CloseIcon from 'assets/icons/close-icon.svg'
import Button from 'components/atoms/Button'
import useEmblaCarousel from 'embla-carousel-react'
import './style.scss'
import { RewardDetailsModalProps } from './interface'
import { useCallback, useContext, useEffect, useState } from 'react'
import { PrevButton, NextButton } from './partials/NavButtons'
import { UserContext } from 'providers/user/context'
import classNames from 'classnames'
import parse from 'html-react-parser'
import { ERewardType } from 'models/rewardType'
import ModalBaseStructure from 'components/atoms/ModalBaseStructure'
import WithdrawModal from '../WithdrawModal'
import ContentSwitcherButton from 'components/atoms/ContentSwitcherButton'
import { ERewardAdditionalInfoType } from 'models/rewardAdditionalInfoType'

const RewardDetailsModal = ({ data, hidePurchaseButton }: RewardDetailsModalProps) => {
  const { userPoints } = useContext(UserContext)
  const { closeModal, openModal } = useModals()
  const [emblaRef, embla] = useEmblaCarousel()

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [contentVisible, setContentVisible] = useState(ERewardAdditionalInfoType.Description)
  const [currentSlide, setCurrentSlide] = useState(1)

  const {
    id,
    title,
    shortDescription,
    longDescription,
    details,
    cost,
    payout,
    rewardImages,
    type
  } = data
  const disabled = userPoints ? userPoints < cost : true
  const formattedPrice = payout ? `${cost} pkt / ${payout} zł` : `${cost} pkt`
  const isAdditional = longDescription.length !== 0 || details.length !== 0

  const scrollPrev = useCallback(() => {
    if (currentSlide > 1) {
      setCurrentSlide(currentSlide - 1)
    }
    return embla && embla.scrollPrev()
  }, [currentSlide, embla])

  const scrollNext = useCallback(() => {
    if (currentSlide < rewardImages.length) {
      setCurrentSlide(currentSlide + 1)
    }
    return embla && embla.scrollNext()
  }, [rewardImages.length, currentSlide, embla])

  const onSelect = useCallback(() => {
    if (!embla) return

    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla])

  const handleActiveButton = () => {
    openModal(
      <WithdrawModal
        rewardID={id}
        rewardType={type}
        rewardName={title}
        rewardFormattedPrice={formattedPrice}
        rewardCost={cost}
      />
    )
  }

  useEffect(() => {
    if (!embla) return

    onSelect()
    embla.on('select', onSelect)
  }, [embla, onSelect])

  return (
    <ModalBaseStructure>
      <div className="reward-details-modal">
        <div className="reward-details-modal__close">
          <button className="reward-details-modal__close-button" onClick={closeModal}>
            <img src={CloseIcon} alt="Close icon" />
          </button>
        </div>
        <div className="reward-details-modal__content">
          {rewardImages && type !== ERewardType.Monetary && (
            <>
              <div className="reward-details-modal__images">
                <div className="reward-details-modal__images-wrapper embla" ref={emblaRef}>
                  <div className="embla__container reward-details-modal__images-container">
                    {rewardImages.map(({ image }, index) => (
                      <div className="embla__slide reward-details-modal__images-slide" key={index}>
                        <img
                          className="reward-details-modal__images-image"
                          src={image}
                          alt={title}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="reward-details-modal__nav">
                  <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                  <div className="reward-details-modal__nav-count">
                    <span className="reward-details-modal__nav-item">{currentSlide}</span>
                    <span className="reward-details-modal__nav-item reward-details-modal__nav-item--separator">
                      /
                    </span>
                    <span className="reward-details-modal__nav-item reward-details-modal__nav-item--disabled">
                      {rewardImages.length}
                    </span>
                  </div>
                  <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
                </div>
              </div>
            </>
          )}
          {type === ERewardType.Monetary && (
            <div className="reward-details-modal__monetary-background">
              <div className="reward-details-modal__monetary-background-content">
                <div className="reward-details-modal__monetary-background-text">
                  <h3 className="reward-details-modal__monetary-background-title">
                    {formattedPrice}
                  </h3>
                  <p className="reward-details-modal__monetary-background-description">
                    Punkty za złotówki
                  </p>
                </div>
              </div>
            </div>
          )}
          <h1 className="reward-details-modal__title">{title}</h1>
          <div className="reward-details-modal__description">{shortDescription}</div>
          {isAdditional && (
            <div className="reward-details-modal__additional">
              <header className="reward-details-modal__additional-header">
                {longDescription && (
                  <ContentSwitcherButton
                    onClick={() => setContentVisible(ERewardAdditionalInfoType.Description)}
                    isActive={contentVisible === ERewardAdditionalInfoType.Description}>
                    Opis
                  </ContentSwitcherButton>
                )}
                {details && (
                  <ContentSwitcherButton
                    onClick={() => setContentVisible(ERewardAdditionalInfoType.Details)}
                    isActive={contentVisible === ERewardAdditionalInfoType.Details}>
                    Szczegóły
                  </ContentSwitcherButton>
                )}
              </header>
              <div className="reward-details-modal__additional-content">
                <div
                  className={classNames('reward-details-modal__additional-description', {
                    'reward-details-modal__additional-description--active':
                      contentVisible === ERewardAdditionalInfoType.Description
                  })}>
                  {parse(longDescription)}
                </div>
                <div
                  className={classNames('reward-details-modal__additional-description', {
                    'reward-details-modal__additional-description--active':
                      contentVisible === ERewardAdditionalInfoType.Details
                  })}>
                  {parse(details)}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="reward-details-modal__action">
          <div className="reward-details-modal__action-points">{formattedPrice}</div>
          {!hidePurchaseButton && (
            <Button disable={disabled} onClick={handleActiveButton}>
              Aktywuj
            </Button>
          )}
        </div>
      </div>
    </ModalBaseStructure>
  )
}

export default RewardDetailsModal
