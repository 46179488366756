import Modal from 'components/organisms/Modal'
import useUser from 'hooks/useUser'

const DeleteAccountModal = () => {
  const { deleteUser } = useUser()

  return (
    <Modal title="Usuń konto" buttonText="Usuń" onSubmit={deleteUser}>
      Czy jesteś pewien, że chcesz usunąć konto? Ta operacja jest nieodwracalna.
    </Modal>
  )
}

export default DeleteAccountModal
