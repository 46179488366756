import { IFiscalData } from 'models/fiscalData'

export const transformFiscalDataRequestData = (data: IFiscalData | Partial<IFiscalData>) => {
  return {
    firstName: data.firstName,
    middleName: data.middleName,
    lastName: data.lastName,
    pesel: data.pesel === '' ? null : data.pesel,
    nip: data.nip === '' ? null : data.nip,
    dateOfBirth: data.dateOfBirth,
    taxOffice: data.taxOffice ? { id: data.taxOffice } : undefined,
    bank: data.bank,
    bankNumber: data.bankNumber,
    country: data.country,
    voivodeship: data.voivodeship,
    city: data.city,
    commune: data.commune,
    county: data.county,
    street: data.street,
    houseNumber: data.houseNumber,
    flatNumber: data.flatNumber,
    postCode: data.postCode,
    postOffice: data.postOffice
  }
}
