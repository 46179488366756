import { FieldValues } from 'react-hook-form'
import useAuth from 'hooks/useAuth'
import { valid } from 'utils/Validators/validators'
import './style.scss'
import Input from 'components/molecules/Input'
import AuthCard from 'components/molecules/AuthCard'
import { PATHS } from 'utils/consts'
import Form from 'components/atoms/Form'
import { Link } from 'react-router-dom'
import useReadLocationStateOnce from 'hooks/useReadLocationStateOnce'

const LoginPage = () => {
  const { errorMessage, isLoading, login } = useAuth()
  const formID = 'loginForm'
  const passwordChangeSuccess = useReadLocationStateOnce<boolean>('passwordChangeSuccess')

  const onSubmit = (formValues: FieldValues): void => {
    const { email, password } = formValues

    login({ email, password })
  }

  return (
    <AuthCard
      title="Logowanie"
      switchBox={{ text: 'Nowy użytkownik?', linkText: 'Zarejestruj się', link: PATHS.register }}
      submitButton={{ text: 'Zaloguj', formID, disabled: isLoading }}>
      <Form className="login-form" formID={formID} onSubmit={onSubmit}>
        <>
          {errorMessage && <p className="login-form__error-message">{errorMessage}</p>}
          {passwordChangeSuccess && (
            <p className="login-form__success-message">
              Hasło zostało zmienione. Możesz się zalogować.
            </p>
          )}

          <Input
            name="email"
            placeholder="Email"
            validators={{ required: valid.required, pattern: valid.emailPattern }}
          />

          <Input
            name="password"
            type="password"
            placeholder="Hasło"
            validators={{ required: valid.required }}
          />

          <Link className="login-form__forgot-password-link" to={PATHS.forgotPassword}>
            Nie pamiętam hasła
          </Link>
        </>
      </Form>
    </AuthCard>
  )
}

export default LoginPage
