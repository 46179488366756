import Button from 'components/atoms/Button'
import IdentifierField from 'components/molecules/IdentifierField'
import Input from 'components/molecules/Input'
import TaxOfficeSelect from 'components/molecules/TaxOfficeSelect'
import VoivodeshipSelect from 'components/molecules/VoivodeshipSelect'
import { UserContext } from 'providers/user/context'
import { useContext } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { handleApiResponse } from 'utils/handleApiResponse'
import { valid } from 'utils/Validators/validators'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import { AddFiscalDataFormProps } from './interface'

const AddFiscalDataForm = ({ closeForm, createFiscalData }: AddFiscalDataFormProps) => {
  const { userData } = useContext(UserContext)
  const methods = useForm()

  const onSubmit = async (formValues: FieldValues) => {
    const {
      firstName,
      middleName,
      lastName,
      pesel,
      nip,
      dateOfBirth,
      taxOffice,
      bank,
      bankNumber,
      country,
      voivodeship,
      city,
      commune,
      county,
      street,
      houseNumber,
      flatNumber,
      postCode,
      postOffice
    } = formValues

    const response = await createFiscalData({
      firstName,
      middleName,
      lastName,
      pesel,
      nip,
      dateOfBirth,
      taxOffice,
      bank,
      bankNumber,
      country,
      voivodeship,
      city,
      commune,
      county,
      street,
      houseNumber,
      flatNumber,
      postCode,
      postOffice
    })

    handleApiResponse(response, closeForm, methods.setError)
  }

  return (
    <FormProvider {...methods}>
      <form className="settings-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Input
          name="firstName"
          placeholder="Imię"
          defaultValue={userData.firstName}
          validators={{ required: valid.required, ...validSchemas.name }}
        />
        <Input name="middleName" placeholder="Drugie imię" validators={validSchemas.name} />
        <Input
          name="lastName"
          placeholder="Nazwisko"
          defaultValue={userData.lastName}
          validators={{ required: valid.required, ...validSchemas.name }}
        />
        <IdentifierField />
        <Input
          name="dateOfBirth"
          placeholder="Data urodzenia"
          validators={{ required: valid.required, pattern: valid.dateOfBirthPattern }}
        />
        <Input
          name="country"
          placeholder="Kraj"
          defaultValue="Polska"
          validators={validSchemas.locationName}
        />
        <VoivodeshipSelect required />
        <Input name="county" placeholder="Powiat" validators={validSchemas.locationName} />
        <Input name="commune" placeholder="Gmina" validators={validSchemas.locationName} />
        <Input name="city" placeholder="Miasto" validators={validSchemas.locationName} />
        <Input name="postCode" placeholder="Kod pocztowy" validators={validSchemas.postCode} />
        <Input
          name="street"
          placeholder="Ulica"
          validators={{ required: valid.required, ...validSchemas.street }}
        />
        <Input
          name="houseNumber"
          placeholder="Numer domu"
          validators={{ required: valid.required, ...validSchemas.buildingNumber }}
        />
        <Input
          name="flatNumber"
          placeholder="Numer mieszkania"
          validators={validSchemas.buildingNumber}
        />
        <Input name="postOffice" placeholder="Poczta" validators={validSchemas.institutionName} />
        <TaxOfficeSelect required />
        <Input name="bank" placeholder="Nazwa banku" validators={validSchemas.institutionName} />
        <Input
          name="bankNumber"
          placeholder="Numer rachunku"
          validators={validSchemas.bankNumber}
        />

        <div className="settings-form__footer">
          <Button className="settings-form__btn" type="submit">
            Zapisz
          </Button>
          <Button className="settings-form__btn btn--outline" onClick={closeForm}>
            Anuluj
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default AddFiscalDataForm
