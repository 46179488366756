import { IRegion } from 'models/region'
import { ISelectOption } from 'models/selectOption'
import { ITaxOffice } from 'models/taxOffice'

export const transformResponseDataToSelectOptions = (
  data: IRegion[] | ITaxOffice[]
): ISelectOption[] => {
  return data.map((item) => {
    return { value: item.id, label: item.name }
  })
}
