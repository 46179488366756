import { ISelectOption } from 'models/selectOption'
import { ENDPOINTS } from 'utils/consts'
import { transformRegionsResponseData } from 'utils/TransformResponseData/regions'
import useData from './useData'

const useRegions = () => {
  const { data, ...rest } = useData<[ISelectOption[], ISelectOption | undefined]>(
    ENDPOINTS.regions,
    { transformResponseData: transformRegionsResponseData }
  )

  const [regionsOptions, defaultRegionOption] = data || []

  return { regionsOptions, defaultRegionOption, ...rest }
}

export default useRegions
