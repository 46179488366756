import IdentifierField from 'components/molecules/IdentifierField'
import Input from 'components/molecules/Input'
import TaxOfficeSelect from 'components/molecules/TaxOfficeSelect'
import VoivodeshipSelect from 'components/molecules/VoivodeshipSelect'
import Modal from 'components/organisms/Modal'
import { IFiscalData } from 'models/fiscalData'
import { useModals } from 'providers/modals/context'
import { FormProvider, useForm } from 'react-hook-form'
import { getIdentifierOption } from 'utils/getIdentifierOption'
import { getTaxOfficeOption } from 'utils/getTaxOfficeOption'
import { getVoivodeship } from 'utils/getVoivodeship'
import { handleApiResponse } from 'utils/handleApiResponse'
import { valid } from 'utils/Validators/validators'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import { ChangeFiscalDataModalProps } from './interface'

const ChangeFiscalDataModal = ({ fiscalData, updateFiscalData }: ChangeFiscalDataModalProps) => {
  const methods = useForm<IFiscalData>()
  const { closeModal } = useModals()

  const formID = 'changeFiscalDataModal'

  const onSubmit = async (formValues: IFiscalData) => {
    let { pesel, nip } = formValues

    nip ? (pesel = null) : (nip = null)

    const response = await updateFiscalData({ ...formValues, nip, pesel })

    handleApiResponse(response, closeModal, methods.setError)
  }

  return (
    <Modal title="Zmień dane podatkowe" buttonText="Zapisz" formID={formID}>
      <FormProvider {...methods}>
        <form id={formID} className="modal__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            name="firstName"
            placeholder="Imię"
            defaultValue={fiscalData.firstName}
            validators={{ required: valid.required, ...validSchemas.name }}
          />
          <Input
            name="middleName"
            placeholder="Drugie imię"
            defaultValue={fiscalData.middleName}
            validators={validSchemas.name}
          />
          <Input
            name="lastName"
            placeholder="Nazwisko"
            defaultValue={fiscalData.lastName}
            validators={{ required: valid.required, ...validSchemas.name }}
          />
          <IdentifierField
            defaultValue={fiscalData.nip || fiscalData.pesel}
            defaultOption={getIdentifierOption(fiscalData.nip ? 'nip' : 'pesel')}
          />
          <Input
            name="dateOfBirth"
            placeholder="Data urodzenia"
            defaultValue={fiscalData.dateOfBirth}
            validators={{ required: valid.required, pattern: valid.dateOfBirthPattern }}
          />
          <Input
            name="country"
            placeholder="Kraj"
            defaultValue={fiscalData.country}
            validators={validSchemas.locationName}
          />
          <VoivodeshipSelect defaultOption={getVoivodeship(fiscalData.voivodeship)} required />
          <Input
            name="county"
            placeholder="Powiat"
            defaultValue={fiscalData.county}
            validators={validSchemas.locationName}
          />
          <Input
            name="commune"
            placeholder="Gmina"
            defaultValue={fiscalData.commune}
            validators={validSchemas.locationName}
          />
          <Input
            name="city"
            placeholder="Miasto"
            defaultValue={fiscalData.city}
            validators={validSchemas.locationName}
          />
          <Input
            name="postCode"
            placeholder="Kod pocztowy"
            defaultValue={fiscalData.postCode}
            validators={validSchemas.postCode}
          />
          <Input
            name="street"
            placeholder="Ulica"
            defaultValue={fiscalData.street}
            validators={{ required: valid.required, ...validSchemas.street }}
          />
          <Input
            name="houseNumber"
            placeholder="Numer domu"
            defaultValue={fiscalData.houseNumber}
            validators={{ required: valid.required, ...validSchemas.buildingNumber }}
          />
          <Input
            name="flatNumber"
            placeholder="Numer mieszkania"
            defaultValue={fiscalData.flatNumber}
            validators={validSchemas.buildingNumber}
          />
          <Input
            name="postOffice"
            placeholder="Poczta"
            defaultValue={fiscalData.postOffice}
            validators={validSchemas.institutionName}
          />
          <TaxOfficeSelect defaultOption={getTaxOfficeOption(fiscalData.taxOffice)} required />
          <Input
            name="bank"
            placeholder="Nazwa banku"
            defaultValue={fiscalData.bank}
            validators={validSchemas.institutionName}
          />
          <Input
            name="bankNumber"
            placeholder="Numer rachunku"
            defaultValue={fiscalData.bankNumber}
            validators={validSchemas.bankNumber}
          />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default ChangeFiscalDataModal
