import IdentifierField from 'components/molecules/IdentifierField'
import Input from 'components/molecules/Input'
import TaxOfficeSelect from 'components/molecules/TaxOfficeSelect'
import VoivodeshipSelect from 'components/molecules/VoivodeshipSelect'
import Modal from 'components/organisms/Modal'
import { IFiscalData } from 'models/fiscalData'
import { useModals } from 'providers/modals/context'
import { UserContext } from 'providers/user/context'
import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { handleApiResponse } from 'utils/handleApiResponse'
import { valid } from 'utils/Validators/validators'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import { AddFiscalDataModalProps } from './interface'

const AddFiscalDataModal = ({ createFiscalData }: AddFiscalDataModalProps) => {
  const methods = useForm<IFiscalData>()
  const { userData } = useContext(UserContext)
  const { closeModal } = useModals()

  const formID = 'addFiscalDataModal'

  const onSubmit = async (formValues: IFiscalData) => {
    const response = await createFiscalData(formValues)

    handleApiResponse(response, closeModal, methods.setError)
  }

  return (
    <Modal title="Dodaj dane podatkowe" buttonText="Dodaj" formID={formID}>
      <FormProvider {...methods}>
        <form id={formID} className="modal__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            name="firstName"
            placeholder="Imię"
            defaultValue={userData.firstName}
            validators={{ required: valid.required, ...validSchemas.name }}
          />
          <Input name="middleName" placeholder="Drugie imię" validators={validSchemas.name} />
          <Input
            name="lastName"
            placeholder="Nazwisko"
            defaultValue={userData.lastName}
            validators={{ required: valid.required, ...validSchemas.name }}
          />
          <IdentifierField />
          <Input
            name="dateOfBirth"
            placeholder="Data urodzenia"
            validators={{ required: valid.required, pattern: valid.dateOfBirthPattern }}
          />
          <Input
            name="country"
            placeholder="Kraj"
            defaultValue="Polska"
            validators={validSchemas.locationName}
          />
          <VoivodeshipSelect required />
          <Input name="county" placeholder="Powiat" validators={validSchemas.locationName} />
          <Input name="commune" placeholder="Gmina" validators={validSchemas.locationName} />
          <Input name="city" placeholder="Miasto" validators={validSchemas.locationName} />
          <Input name="postCode" placeholder="Kod pocztowy" validators={validSchemas.postCode} />
          <Input
            name="street"
            placeholder="Ulica"
            validators={{ required: valid.required, ...validSchemas.street }}
          />
          <Input
            name="houseNumber"
            placeholder="Numer domu"
            validators={{ required: valid.required, ...validSchemas.buildingNumber }}
          />
          <Input
            name="flatNumber"
            placeholder="Numer mieszkania"
            validators={validSchemas.buildingNumber}
          />
          <Input name="postOffice" placeholder="Poczta" validators={validSchemas.institutionName} />
          <TaxOfficeSelect required />
          <Input name="bank" placeholder="Nazwa banku" validators={validSchemas.institutionName} />
          <Input
            name="bankNumber"
            placeholder="Numer rachunku"
            validators={validSchemas.bankNumber}
          />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default AddFiscalDataModal
