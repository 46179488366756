import Button from 'components/atoms/Button'
import { Link } from 'react-router-dom'
import { AuthCardProps } from './interface'
import './style.scss'

const AuthCard = ({ children, title, switchBox, description, submitButton }: AuthCardProps) => {
  return (
    <div className="auth-card">
      <div className="auth-card__header">
        <h1 className="auth-card__title">{title}</h1>

        {switchBox && (
          <div className="auth-card__switch">
            <p className="switch__text">{switchBox.text}</p>
            <Link className="switch__link" to={switchBox.link}>
              {switchBox.linkText}
            </Link>
          </div>
        )}

        {description && <p className="auth-card__description">{description}</p>}
      </div>

      {children}

      {submitButton && (
        <Button
          className="auth-card__submit-btn"
          disable={submitButton.disabled}
          type="submit"
          form={submitButton.formID}>
          {submitButton.text}
        </Button>
      )}
    </div>
  )
}

export default AuthCard
