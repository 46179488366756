import RewardCard from 'components/molecules/RewardCard'
import { RewardCardListProps } from './interface'
import './style.scss'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import RewardsGridLayout from 'components/atoms/RewardsGridLayout'

const RewardCardList = ({ products, isLoading }: RewardCardListProps) => {
  if (isLoading)
    return (
      <ul className="reward-card-list">
        {Array(12)
          .fill(null)
          .map((_, index) => (
            <li className="reward-card-list__item reward-card-list__item--skeleton" key={index}>
              <div className="reward-card-list-skeleton">
                <div className="reward-card-list-skeleton__header">
                  <Skeleton height={190} width={190} />
                </div>
                <div className="reward-card-list-skeleton__title">
                  <Skeleton height={60} />
                </div>
                <div className="reward-card-list-skeleton__description">
                  <Skeleton height={90} />
                </div>
                <div className="reward-card-list-skeleton__action">
                  <Skeleton height={30} width={90} />
                  <Skeleton height={30} width={120} />
                </div>
              </div>
            </li>
          ))}
      </ul>
    )
  return (
    <RewardsGridLayout>
      {products.map((product) => (
        <RewardCard key={product.id} {...product} />
      ))}
    </RewardsGridLayout>
  )
}

export default RewardCardList
