export const valid = {
  required: {
    value: true,
    message: 'Pole jest wymagane'
  },
  emailPattern: {
    value:
      /^(([^<>()[\]\\.,;: @"]+(\.[^<>()[\]\\.,;: @"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Nieprawidłowy adres email'
  },
  passwordPattern: {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^\s]{8,}$/,
    message: 'Hasło musi zawierać co najmniej 8 znaków, 1 dużą literę, 1 małą literę oraz 1 cyfrę'
  },
  namesPattern: {
    value:
      /^[a-zA-Z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff\u0100-\u0148\u014a-\u017f]{1}[a-zA-Z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff\u0100-\u0148\u014a-\u017f ,.'-]{0,28}[a-zA-Z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff\u0100-\u0148\u014a-\u017f.]{1}$/,
    message: 'Nieprawidłowy format'
  },
  phonePattern: {
    value: /^[1-9]{1}[-\s\\.]?[0-9]{2}[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{3}$/im,
    message: 'Nieprawidłowy numer telefonu'
  },
  postCodePattern: {
    value: /^\d{2}-\d{3}$/,
    message: 'Nieprawidłowy format (XX-XXX)'
  },
  textFieldPattern: {
    value: /^\D*$/,
    message: 'To pole nie może składać się z cyfr'
  },
  bankNumberPattern: {
    value: /^\d{2} \d{4} \d{4} \d{4} \d{4} \d{4} \d{4}$/,
    message: 'Nieprawidłowy format (XX XXXX XXXX XXXX XXXX XXXX XXXX)'
  },
  buildingNumberPattern: {
    value: /^[a-zA-Z0-9]+$/,
    message: 'To pole może składać się jedynie z cyfr i liter'
  },
  dateOfBirthPattern: {
    value: /^(19[0-9][0-9]|20[01][0-9]|202[0-2])-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/,
    message: 'Nieprawidłowy format (YYYY-MM-DD) lub rok (1900-2022)'
  },
  peselPattern: {
    value: /^\d{11}$/,
    message: 'Nieprawidłowy numer PESEL'
  },
  nipPattern: {
    value: /^\d{10}$/,
    message: 'Nieprawidłowy numer NIP'
  },
  differentPasswordsMessage: 'Hasła nie są identyczne',
  minLength: (length: number) => {
    return {
      value: length,
      message: `Minimalna ilość znaków dla tego pola to ${length}`
    }
  },
  maxLength: (length: number) => {
    return {
      value: length,
      message: `Maksymalna ilość znaków dla tego pola to ${length}`
    }
  }
}
