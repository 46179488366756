import Input from 'components/molecules/Input'
import PhoneField from 'components/molecules/PhoneField'
import VoivodeshipSelect from 'components/molecules/VoivodeshipSelect'
import Modal from 'components/organisms/Modal'
import { IShippingData } from 'models/shippingData'
import { useModals } from 'providers/modals/context'
import { UserContext } from 'providers/user/context'
import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { handleApiResponse } from 'utils/handleApiResponse'
import { removeCountryCode } from 'utils/removeCountryCode'
import { valid } from 'utils/Validators/validators'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import { AddShippingDataModalProps } from './interface'

const AddShippingDataModal = ({ createShippingData }: AddShippingDataModalProps) => {
  const { userData } = useContext(UserContext)
  const methods = useForm<Omit<IShippingData, 'id'>>()
  const { closeModal } = useModals()

  const formID = 'addShippingDataModal'

  const onSubmit = async (formValues: Omit<IShippingData, 'id'>) => {
    const response = await createShippingData(formValues)

    handleApiResponse(response, closeModal, methods.setError)
  }

  return (
    <Modal title="Dodaj dane do wysyłki" buttonText="Zapisz" formID={formID}>
      <FormProvider {...methods}>
        <form id={formID} className="modal__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            name="fullName"
            placeholder="Imię i nazwisko"
            defaultValue={`${userData.firstName} ${userData.lastName}`}
            validators={{ required: valid.required, ...validSchemas.name }}
          />
          <PhoneField
            required
            defaultValue={
              userData.phoneNumber ? removeCountryCode(userData.phoneNumber) : undefined
            }
          />
          <Input
            name="firstAddressLine"
            placeholder="Pierwsza linia adresu"
            validators={{ required: valid.required }}
          />
          <Input name="secondaryAddressLine" placeholder="Druga linia adresu" />
          <Input name="city" placeholder="Miasto" validators={validSchemas.locationName} />
          <VoivodeshipSelect required />
          <Input name="postCode" placeholder="Kod pocztowy" validators={validSchemas.postCode} />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default AddShippingDataModal
