import classNames from 'classnames'
import ErrorMessage from 'components/atoms/ErrorMessage'
import { useFormContext } from 'react-hook-form'
import { CheckboxProps } from './interface'
import './style.scss'

const Checkbox = ({
  children,
  className,
  name,
  defaultValue = false,
  validators = {}
}: CheckboxProps) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <label
      className={classNames(`checkbox-wrapper`, className, {
        'checkbox-wrapper--required': validators.required
      })}>
      <input
        className="checkbox-wrapper__checkbox"
        data-testid={name}
        type="checkbox"
        defaultChecked={defaultValue}
        {...register(name, validators)}
      />
      <span className="checkbox-wrapper__indicator" />
      <p className="checkbox-wrapper__content">{children}</p>
      {errors[name] && (
        <ErrorMessage
          className="checkbox-wrapper__error-message"
          message={`${errors[name]?.message}`}
          fieldName={name}
        />
      )}
    </label>
  )
}

export default Checkbox
