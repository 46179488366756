import { UserContext } from 'providers/user/context'
import { useContext } from 'react'
import MetricTile from '../MetricTile'
import './style.scss'
import { PurchaseHistoryMetricsProps } from './interface'
import MedalIcon from '../MedalIcon'
import TrophyIcon from '../TrophyIcon'

const PurchaseHistoryMetrics = ({ purchasedItemsCount }: PurchaseHistoryMetricsProps) => {
  const { userPoints } = useContext(UserContext)

  return (
    <div className="purchase-history-metrics">
      <MetricTile Icon={<MedalIcon />} description="Twoje punkty" value={userPoints || 0} />
      <MetricTile
        Icon={<TrophyIcon />}
        description="Twoje nagrody"
        value={purchasedItemsCount || 0}
      />
    </div>
  )
}

export default PurchaseHistoryMetrics
