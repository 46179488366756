import classNames from 'classnames'
import { ECandidateStatus } from 'models/candidateStatus'
import { StatusEclipseProps } from './interface'
import './style.scss'

const StatusEclipse = ({ status }: StatusEclipseProps) => {
  return (
    <span
      className={classNames('status-eclipse', {
        'status-eclipse--new': status === ECandidateStatus.NEW,
        'status-eclipse--no-response': status === ECandidateStatus.NO_RESPONSE,
        'status-eclipse--confirmed': status === ECandidateStatus.CONFIRMED,
        'status-eclipse--non-compliant': status === ECandidateStatus.NON_COMPLIANT,
        'status-eclipse--invited': status === ECandidateStatus.INVITED,
        'status-eclipse--awaiting-meeting': status === ECandidateStatus.AWAITING_MEETING,
        'status-eclipse--resigned': status === ECandidateStatus.RESIGNED,
        'status-eclipse--rejected': status === ECandidateStatus.REJECTED,
        'status-eclipse--dropped-out': status === ECandidateStatus.DROPPED_OUT,
        'status-eclipse--hired-sale': status === ECandidateStatus.HIRED_SALE
      })}
    />
  )
}

export default StatusEclipse
