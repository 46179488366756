import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { CardFooterProps } from './interface'
import './style.scss'

const CardFooter = ({ className, text, link }: CardFooterProps) => {
  const { text: linkText, redirectionPath: linkRedirectionPath } = link

  return (
    <div className={classNames('card-footer', className)}>
      <p className="card-footer__text">{text}</p>
      <Link className="card-footer__link" to={linkRedirectionPath}>
        {linkText}
      </Link>
    </div>
  )
}

export default CardFooter
