import Pagination from 'components/atoms/Pagination'
import RewardHeader from 'components/molecules/RewardHeader'
import RewardCardList from 'components/organisms/RewardCardList'
import useRewards from 'hooks/useRewards'
import './style.scss'

const RewardShopPageContent = () => {
  const { rewardsList, rewardsCount, pageCount, isLoading, getRewards } = useRewards()

  return (
    <div className="reward-shop-page-content">
      <RewardHeader rewardCount={rewardsCount} />
      <RewardCardList products={rewardsList} {...{ isLoading }} />
      {pageCount > 1 && (
        <Pagination
          className="reward-shop-page-content__pagination"
          pageCount={pageCount}
          apiRequest={getRewards}
        />
      )}
    </div>
  )
}

export default RewardShopPageContent
