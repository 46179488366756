import { useRewardShopFiltersContext } from 'providers/rewardShopFilters/context'
import React, { useState } from 'react'
import { Range, getTrackBackground } from 'react-range'

import { RewardFiltersProps, RewardOrder } from './interface'
import './style.scss'

const minRange = 100
const maxRange = 20000

const RewardFilters = ({ rewardCount }: RewardFiltersProps) => {
  const { pointsRange, setPointsRange, setSortType, sortType } = useRewardShopFiltersContext()
  const [value, setValue] = useState(pointsRange)

  const [startValue, endValue] = value
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-clr')

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortType(event.target.value as RewardOrder)
  }

  return (
    <div className="reward-filters">
      <div className="reward-filters__item">
        <p className="reward-filters__item-title">Sortuj według</p>
        <select
          name="sort"
          id="sort"
          className="reward-filters__select"
          onChange={onSelectChange}
          value={sortType}>
          <option value={RewardOrder.MOST_POPULAR}>Najpopularniejsze</option>
          <option value={RewardOrder.NEWEST}>Najnowsze</option>
          <option value={RewardOrder.PRICE_DOWN}>Od najtańszych</option>
          <option value={RewardOrder.PRICE_UP}>Od najdroższych</option>
        </select>
      </div>
      <div className="reward-filters__item">
        <p className="reward-filters__item-title">Ilość punktów</p>
        <div className="reward-filters__slider">
          <span className="reward-filters__slider-values">{startValue}</span>
          <Range
            step={50}
            min={minRange}
            max={maxRange}
            values={value}
            onFinalChange={(value) => setPointsRange(value as [number, number])}
            onChange={(value) => setValue(value as [number, number])}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                className="reward-filters__range-track"
                style={{
                  background: getTrackBackground({
                    values: value,
                    colors: ['#d8d8d8', primaryColor, '#d8d8d8'],
                    min: minRange,
                    max: maxRange
                  })
                }}>
                {children}
              </div>
            )}
            renderThumb={({ props }) => <div {...props} className="reward-filters__range-thumb" />}
          />
          <span className="reward-filters__slider-values">{endValue}</span>
        </div>
      </div>
      <div className="reward-filters__item">
        <p className="reward-filters__item-title">Ilość nagród</p>
        <p className="reward-filters__count">{rewardCount === undefined ? '-' : rewardCount}</p>
      </div>
    </div>
  )
}

export default RewardFilters
