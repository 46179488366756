import { formatISODate } from 'utils/formatISODate'
import CandidateStatus from '../../molecules/CandidateStatus'
import { CandidatesItemProps } from './interface'
import './style.scss'
import useWindowMatchMedia from 'hooks/useWindowMatchMedia'
import { useState } from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import CandidateLeadCategories from 'components/molecules/CandidateLeadCategories'
import { BREAKPOINTS } from 'consts/breakpoints'

const CandidatesItem = ({ candidate }: CandidatesItemProps) => {
  const [desktopView] = useWindowMatchMedia(BREAKPOINTS.tablet, 'min')
  const [shouldExpandData, setShouldExpandData] = useState(false)

  const { firstName, lastName, phoneNumber, email, leadCategories, status, awardedPoints } =
    candidate
  const createdAt = formatISODate(candidate.createdAt, 'dd.MM.y')

  return (
    <>
      {desktopView ? (
        <div className="candidates-item">
          <span>{createdAt}</span>
          <span>{firstName}</span>
          <span>{lastName || '-'}</span>
          <span>{phoneNumber}</span>
          <span>{email || '-'}</span>
          <CandidateLeadCategories leadCategories={leadCategories} />
          <CandidateStatus status={status} />
          <span>{awardedPoints}</span>
        </div>
      ) : (
        <div className="candidates-item candidates-item--mobile">
          <strong>Imię i nazwisko</strong>
          <span>{`${firstName} ${lastName || ''}`}</span>
          <strong>Telefon</strong>
          <span>{phoneNumber}</span>
          {shouldExpandData && (
            <>
              <strong>Email</strong>
              <span>{email || '-'}</span>
              <strong>Status</strong>
              <CandidateStatus status={status} />
              <strong>Punkty</strong>
              <span>{awardedPoints}</span>
              <strong>Data dodania</strong>
              <span>{createdAt}</span>
              <strong>Kategorie</strong>
              <CandidateLeadCategories leadCategories={leadCategories} />
            </>
          )}
          <button
            className="candidates-item__btn"
            onClick={() => setShouldExpandData(!shouldExpandData)}>
            {shouldExpandData ? <FaAngleUp /> : <FaAngleDown />}
          </button>
        </div>
      )}
    </>
  )
}

export default CandidatesItem
