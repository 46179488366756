import { IPurchasedItem } from 'models/purchasedItem'
import { IApiPaginatedListResponse } from 'models/responses/apiPaginatedListReponse'
import { ENDPOINTS, RECORDS_PER_PAGE } from 'utils/consts'
import useData from './useData'

const usePurchaseHistory = () => {
  const {
    data,
    pageCount,
    getData: getPurchasedItems
  } = useData<IApiPaginatedListResponse<IPurchasedItem>>(ENDPOINTS.purchaseHistory, {
    recordsPerPage: RECORDS_PER_PAGE.purchasedItems
  })

  const { count: purchasedItemsCount, results: purchasedItemsList } = data || {}

  return { purchasedItemsCount, purchasedItemsList, pageCount, getPurchasedItems }
}

export default usePurchaseHistory
