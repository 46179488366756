import { downloadFile } from 'utils/downloadFile'
import { renderIconBasedOnFileExtension } from 'utils/renderIconBasedOnFileExtension'
import { ArticleAttachmentItemProps } from './interface'
import './style.scss'

const ArticleAttachmentItem = ({ attachment }: ArticleAttachmentItemProps) => {
  const onClick = () => {
    downloadFile(attachment.file, attachment.fileName)
  }

  return (
    <div className="article-attachment-item" onClick={onClick}>
      {renderIconBasedOnFileExtension(attachment.fileExtension)}
      <p className="article-attachment-item__filename">{attachment.fileName}</p>
    </div>
  )
}

export default ArticleAttachmentItem
