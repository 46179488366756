import ModalBaseStructure from 'components/atoms/ModalBaseStructure'
import { useModals } from 'providers/modals/context'
import { DescriptiveModalProps } from './interface'
import './style.scss'

const DescriptiveModal = ({ children, title }: DescriptiveModalProps) => {
  const { closeModal } = useModals()

  return (
    <ModalBaseStructure>
      <div className="descriptive-modal">
        <div className="descriptive-modal__header">
          <h3 className="header__title">{title}</h3>
          <i className="header__xmark fas fa-xmark" onClick={closeModal} />
        </div>
        <div className="descriptive-modal__body">{children}</div>
      </div>
    </ModalBaseStructure>
  )
}

export default DescriptiveModal
