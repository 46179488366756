import Button from 'components/atoms/Button'
import Tile from 'components/atoms/Tile'
import useRouteChange from 'hooks/useRouteChange'
import { IntroductionStepProps } from './interface'
import './style.scss'

const IntroductionStep = ({ index, title, content, button }: IntroductionStepProps) => {
  const { changeRoute } = useRouteChange()

  return (
    <Tile className="introduction-step" shadow="normal" borderTop>
      <span className="introduction-step__bg-index">{index}</span>
      <h1 className="introduction-step__title">{title}</h1>
      <p className="introduction-step__content">{content}</p>
      {button.text && (
        <Button
          className="introduction-step__btn"
          onClick={() => button.link && changeRoute(button.link)}>
          {button.text}
        </Button>
      )}
    </Tile>
  )
}

export default IntroductionStep
