import IdentifierField from 'components/molecules/IdentifierField'
import Modal from 'components/organisms/Modal'
import { useModals } from 'providers/modals/context'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { getModalButtonText } from 'utils/getModalButtonText'
import { getModalTitlePrefix } from 'utils/getModalTitlePrefix'
import { handleApiResponse } from 'utils/handleApiResponse'
import { FiscalDataModalProps } from './_interface'

const IdentifierModal = ({
  action,
  defaultOption,
  defaultValue,
  updateFiscalData
}: FiscalDataModalProps) => {
  const methods = useForm()
  const { closeModal } = useModals()
  const formID = 'IdentifierForm'

  const onSubmit = async (formValues: FieldValues) => {
    let { pesel, nip } = formValues

    nip ? (pesel = null) : (nip = null)

    const response = await updateFiscalData({ pesel, nip })

    handleApiResponse(response, closeModal, methods.setError)
  }

  return (
    <Modal
      title={`${getModalTitlePrefix(action)} identyfikator`}
      buttonText={getModalButtonText(action)}
      formID={formID}
      visibleOverflow>
      <FormProvider {...methods}>
        <form id={formID} className="modal__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <IdentifierField defaultOption={defaultOption} defaultValue={defaultValue} />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default IdentifierModal
