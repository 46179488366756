import VoivodeshipSelect from 'components/molecules/VoivodeshipSelect'
import Modal from 'components/organisms/Modal'
import { useModals } from 'providers/modals/context'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { getModalButtonText } from 'utils/getModalButtonText'
import { getModalTitlePrefix } from 'utils/getModalTitlePrefix'
import { handleApiResponse } from 'utils/handleApiResponse'
import { FiscalDataModalProps } from './_interface'

const VoivodeshipModal = ({ action, defaultOption, updateFiscalData }: FiscalDataModalProps) => {
  const methods = useForm()
  const { closeModal } = useModals()
  const formID = 'voivodeshipForm'

  const onSubmit = async (formValues: FieldValues) => {
    const { voivodeship } = formValues

    const response = await updateFiscalData({ voivodeship })

    handleApiResponse(response, closeModal, methods.setError)
  }

  return (
    <Modal
      title={`${getModalTitlePrefix(action)} województwo`}
      buttonText={getModalButtonText(action)}
      formID={formID}
      visibleOverflow>
      <FormProvider {...methods}>
        <form id={formID} className="modal__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <VoivodeshipSelect required defaultOption={defaultOption} />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default VoivodeshipModal
