import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'

const useMessageAfterRecommendation = () => {
  const { data: messageAfterRecommendation } = useData<string>(
    ENDPOINTS.messageAfterRecommendation,
    {
      dataLocation: 'prompt1'
    }
  )

  return { messageAfterRecommendation }
}

export default useMessageAfterRecommendation
