import { EWithdrawDataType } from 'models/withdrawDataType'
import AddFiscalDataModal from 'pages/RewardShopPage/partials/modals/AddFiscalDataModal'
import AddPayoutDataModal from 'pages/RewardShopPage/partials/modals/AddPayoutDataModal'
import AddShippingDataModal from 'pages/RewardShopPage/partials/modals/AddShippingDataModal'
import { useModals } from 'providers/modals/context'
import { Link } from 'react-router-dom'
import { PATHS } from 'utils/consts'
import Button from '../Button'
import { WithdrawNoDataProps } from './interface'
import './style.scss'

const WithdrawNoData = ({
  dataType,
  createPayoutData,
  createShippingData,
  createFiscalData
}: WithdrawNoDataProps) => {
  const { openModal } = useModals()

  const handleAddButton = () => {
    switch (dataType) {
      case EWithdrawDataType.Payout:
        if (createPayoutData) openModal(<AddPayoutDataModal createPayoutData={createPayoutData} />)
        break
      case EWithdrawDataType.Shipping:
        if (createShippingData)
          openModal(<AddShippingDataModal createShippingData={createShippingData} />)
        break
      case EWithdrawDataType.Tax:
        if (createFiscalData) openModal(<AddFiscalDataModal createFiscalData={createFiscalData} />)
        break
    }
  }

  return (
    <div className="withdraw-no-data">
      <p className="withdraw-no-data__content">
        Jeśli chcesz aktywować punkty na nagrody, dodaj {`${dataType}`}. Możesz to zrobić tutaj lub{' '}
        <Link className="withdraw-no-data__redirect" to={PATHS.settings}>
          przejść do ustawień
        </Link>{' '}
        swojego profilu.
      </p>
      <Button className="withdraw-no-data__btn btn--outline" onClick={handleAddButton}>
        Dodaj
      </Button>
    </div>
  )
}

export default WithdrawNoData
