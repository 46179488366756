import Button from 'components/atoms/Button'
import LineSeparator from 'components/atoms/LineSeparator'
import Tile from 'components/atoms/Tile'
import useRouteChange from 'hooks/useRouteChange'
import { LandingCardProps } from './interface'
import './style.scss'

const LandingCard = ({ title, content, button }: LandingCardProps) => {
  const { changeRoute } = useRouteChange()

  return (
    <Tile className="landing-card" shadow="normal">
      <h3 className="landing-card__title">{title}</h3>
      <LineSeparator />
      <p className="landing-card__content">{content}</p>
      {button.text && (
        <Button
          className="landing-card__btn"
          onClick={() => button.link && changeRoute(button.link)}>
          {button.text}
        </Button>
      )}
    </Tile>
  )
}

export default LandingCard
