import './style.scss'

const TrophyIcon = () => (
  <svg
    className="trophy-icon"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_594_6747)">
      <path
        className="trophy-icon__path--primary-clr-dark"
        d="M32.8361 0H29.7959L27.6376 2.10938H32.8361C33.4181 2.10938 33.8907 2.58206 33.8907 3.16406V4.77081C33.8907 6.89667 33.1596 8.98132 31.8308 10.6416C30.6967 12.06 29.1869 13.1125 27.4648 13.6862L25.9776 14.1817C25.4255 14.366 25.1267 14.9634 25.3101 15.5165C25.4574 15.9585 25.8693 16.2375 26.3113 16.2375C26.4214 16.2375 26.5337 16.2199 26.645 16.184L28.1323 15.6874C30.2405 14.9851 32.0893 13.6956 33.4788 11.96C35.1042 9.92697 36.0001 7.37347 36.0001 4.77081V3.16406C36.0001 1.41943 34.5807 0 32.8361 0Z"
      />
      <path
        className="trophy-icon__path--primary-clr"
        d="M10.0226 14.1817L8.53528 13.6873C6.81317 13.1125 5.30338 12.06 4.16931 10.6416C2.84052 8.98132 2.10938 6.89667 2.10938 4.77081V3.16406C2.10938 2.58206 2.58206 2.10938 3.16406 2.10938H8.61438L6.20425 0H3.16406C1.41943 0 0 1.41943 0 3.16406V4.77081C0 7.37347 0.895935 9.92697 2.52136 11.96C3.91086 13.6956 5.75958 14.9851 7.86786 15.6885L9.35513 16.184C9.46637 16.2199 9.57871 16.2375 9.68884 16.2375C10.1308 16.2375 10.5428 15.9585 10.69 15.5165C10.8734 14.9634 10.5746 14.366 10.0226 14.1817Z"
      />
      <path
        className="trophy-icon__path--primary-clr-lightest"
        d="M21.2343 20.5876V22.1484C21.2343 22.739 21.6982 23.2031 22.289 23.2031C24.0399 23.2031 25.453 24.6162 25.453 26.3672V28.4766C25.453 29.0671 24.9891 29.5312 24.3983 29.5312H11.6015C11.0107 29.5312 10.5468 29.0671 10.5468 28.4766V26.3672C10.5468 24.6162 11.9599 23.2031 13.7108 23.2031C14.3016 23.2031 14.7655 22.739 14.7655 22.1484V20.5876C10.842 19.3428 7.97351 15.8835 7.50934 11.7702L6.20142 0H29.7984L28.4905 11.7702C28.0263 15.8835 25.1578 19.3428 21.2343 20.5876Z"
      />
      <path
        className="trophy-icon__path--primary-clr"
        d="M24.3984 29.5312H18V0H29.7985L28.4906 11.7702C28.0264 15.8835 25.1579 19.3428 21.2344 20.5876V22.1484C21.2344 22.739 21.6983 23.2031 22.2891 23.2031C24.04 23.2031 25.4531 24.6162 25.4531 26.3672V28.4766C25.4531 29.0671 24.9892 29.5312 24.3984 29.5312Z"
      />
      <path
        className="trophy-icon__path--primary-clr"
        d="M23.2312 7.95215C23.1046 7.55142 22.7673 7.27731 22.3665 7.21414L20.0039 6.87659L18.9493 4.78809C18.7595 4.42966 18.3796 4.26074 18 4.26074C17.6205 4.26074 17.2406 4.42966 17.0508 4.78809L15.9961 6.87659L13.6335 7.21387C13.2328 7.27731 12.8955 7.55142 12.7689 7.95215C12.6634 8.332 12.7689 8.7536 13.0641 9.02798L14.7516 10.6523L14.3509 12.9303C14.2875 13.3102 14.4352 13.7109 14.7728 13.9641C15.0892 14.196 15.5322 14.2382 15.8695 14.0485L18 12.9515L20.1306 14.0485C20.489 14.2382 20.9109 14.196 21.2275 13.9641C21.5648 13.7109 21.7126 13.3102 21.6491 12.9303L21.2484 10.6523L22.9359 9.02798C23.2312 8.75388 23.3369 8.332 23.2312 7.95215Z"
      />
      <path
        d="M27.5625 28.4766V34.861H8.4375V28.4766C8.4375 27.8858 8.9014 27.4219 9.49219 27.4219H26.5078C27.0986 27.4219 27.5625 27.8858 27.5625 28.4766Z"
        fill="#666666"
        fillOpacity="0.87"
      />
      <path
        className="trophy-icon__path--primary-clr-dark"
        d="M20.1305 14.0485L18 12.9515V4.26074C18.3796 4.26074 18.7594 4.42966 18.9492 4.78809L20.0039 6.87659L22.3665 7.21414C22.7672 7.27731 23.1045 7.55142 23.2311 7.95215C23.3366 8.332 23.2311 8.75388 22.9359 9.02798L21.2484 10.6523L21.6491 12.9306C21.7126 13.3102 21.5648 13.7109 21.2272 13.9641C20.9108 14.196 20.489 14.2382 20.1305 14.0485Z"
      />
      <path
        d="M27.5625 28.4766V34.861H18V27.4219H26.5078C27.0986 27.4219 27.5625 27.8858 27.5625 28.4766Z"
        fill="#333333"
      />
      <path
        d="M29.6719 34.9453C29.6719 35.5358 29.208 36 28.6172 36H7.38281C6.79202 36 6.32812 35.5358 6.32812 34.9453C6.32812 34.3545 6.79202 33.8906 7.38281 33.8906H28.6172C29.208 33.8906 29.6719 34.3545 29.6719 34.9453Z"
        fill="#666666"
        fillOpacity="0.87"
      />
      <path
        d="M29.6719 34.9453C29.6719 35.5358 29.208 36 28.6172 36H18V33.8906H28.6172C29.208 33.8906 29.6719 34.3545 29.6719 34.9453Z"
        fill="#333333"
      />
    </g>
    <defs>
      <clipPath id="clip0_594_6747">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default TrophyIcon
