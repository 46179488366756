import useHomePageIcons from 'pages/HomePage/hooks/useHomePageIcons'
import IconBox from '../../components/IconBox'
import './style.scss'

const IconsSection = () => {
  const { iconsItemList } = useHomePageIcons()

  return (
    <section className="icons-section">
      <div className="icons-section__icons-wrapper">
        {iconsItemList?.map((item, index) => (
          <IconBox key={index} icon={item.icon} content={item.description} />
        ))}
      </div>
    </section>
  )
}

export default IconsSection
