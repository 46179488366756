import Pagination from 'components/atoms/Pagination'
import RewardsGridLayout from 'components/atoms/RewardsGridLayout'
import PurchasedRewardCard from '../PurchasedRewardCard'
import { PurchasedItemsListProps } from './interface'

const PurchasedItemsList = ({
  purchasedItemsList,
  pageCount,
  getPurchasedItems
}: PurchasedItemsListProps) => (
  <div>
    <RewardsGridLayout>
      {purchasedItemsList.map((purchasedItem) => (
        <PurchasedRewardCard key={purchasedItem.id} purchasedItem={purchasedItem} />
      ))}
    </RewardsGridLayout>
    {pageCount > 1 && <Pagination pageCount={pageCount} apiRequest={getPurchasedItems} />}
  </div>
)

export default PurchasedItemsList
