import Input from 'components/molecules/Input'
import PhoneField from 'components/molecules/PhoneField'
import { valid } from 'utils/Validators/validators'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import Button from '../../atoms/Button'
import { useFormContext } from 'react-hook-form'
import './style.scss'
import { InviteAddressProps } from './interface'

const inputsNamesToValidate = ['firstName', 'lastName', 'phoneNumber']
const inputsNames = [...inputsNamesToValidate, 'email']

const InviteAddress = ({ onNextStepClick }: InviteAddressProps) => {
  const methods = useFormContext()
  const firstNameValue = methods.watch('firstName')
  const lastNameValue = methods.watch('lastName')
  const phoneNumberValue = methods.watch('phoneNumber')

  const onClick = async () => {
    try {
      const result = await methods.trigger(inputsNames)
      if (!result) {
        return
      }

      onNextStepClick(inputsNames)
    } catch (err) {
      console.error(err)
    }
  }

  const isButtonDisabled = !firstNameValue || !lastNameValue || !phoneNumberValue

  return (
    <div className="invite-address">
      <h1 className="invite-address__title">
        Dodaj adres email i sprawdź czy Twoje dane się zgadzają
      </h1>
      <div className="invite-address__inputs">
        <Input
          name="firstName"
          placeholder="Imię"
          validators={{ required: valid.required, ...validSchemas.name }}
        />
        <Input
          name="lastName"
          placeholder="Nazwisko"
          validators={{ required: valid.required, ...validSchemas.name }}
        />
        <PhoneField required />
        <Input name="email" placeholder="Email" validators={{ pattern: valid.emailPattern }} />
      </div>
      <div className="invite-address__action">
        <Button
          type="button"
          form="inviteForm"
          className="invite-address__btn btn"
          disable={isButtonDisabled}
          onClick={onClick}>
          Dalej
        </Button>
      </div>
    </div>
  )
}

export default InviteAddress
