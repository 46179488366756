import Controller from 'components/atoms/Controller'
import Select from 'components/atoms/Select'
import { VOIVODESHIPS } from 'utils/consts'
import { valid } from 'utils/Validators/validators'
import { VoivodeshipSelectProps } from './interface'

const VoivodeshipSelect = ({ required, defaultOption }: VoivodeshipSelectProps) => {
  return (
    <Controller
      name="voivodeship"
      rules={{ required: required ? valid.required : false }}
      Element={Select}
      elementProps={{ options: VOIVODESHIPS, placeholder: 'Województwo', defaultOption }}
      defaultValue={defaultOption?.value}
    />
  )
}

export default VoivodeshipSelect
