import AuthLayout from 'components/atoms/AuthLayout'
import SaveCandidateHashRoute from 'components/atoms/SaveCandidateHashRoute'
import SharedLayout from 'components/atoms/SharedLayout'
import InvitePage from 'pages/InvitePage'
import SettingsPage from 'pages/SettingsPage'
import { Route, Routes } from 'react-router-dom'
import './App.scss'
import ProtectedRoutes from './components/atoms/ProtectedRoutes'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import ProfilePage from './pages/ProfilePage'
import ChangePasswordPage from './pages/ChangePasswordPage'
import RegisterPage from './pages/RegisterPage'
import { PATHS } from './utils/consts'
import RewardShopPage from 'pages/RewardShopPage'
import MeetingPage from 'pages/MeetingPage'
import AlertContainer from 'components/atoms/AlertContainer'
import useCustomColors from 'hooks/useCustomColors'
import EduPage from 'pages/EduPage'
import ArticleDetailsPage from 'pages/ArticleDetailsPage'

function App() {
  useCustomColors()

  return (
    <div className="app">
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path={PATHS.login} element={<LoginPage />} />
          <Route path={PATHS.register} element={<RegisterPage />} />
          <Route path={PATHS.changePassword} element={<ChangePasswordPage />} />
          <Route path={PATHS.forgotPassword} element={<ForgotPasswordPage />} />
        </Route>

        <Route element={<SharedLayout />}>
          <Route path={PATHS.home} element={<HomePage />} />
          <Route path={PATHS.eduZone} element={<EduPage />} />
          <Route path={PATHS.articleDetails} element={<ArticleDetailsPage />} />
          <Route path={PATHS.invite} element={<InvitePage />} />
          <Route
            path={PATHS.inviteHashed}
            element={<SaveCandidateHashRoute redirectionPath={PATHS.invite} />}
          />
          <Route path={PATHS.meeting} element={<MeetingPage />} />
          <Route
            path={PATHS.meetingHashed}
            element={<SaveCandidateHashRoute redirectionPath={PATHS.meeting} />}
          />

          <Route element={<ProtectedRoutes />}>
            <Route path={PATHS.rewardShop} element={<RewardShopPage />} />
            <Route path={PATHS.profile} element={<ProfilePage />} />
            <Route path={PATHS.settings} element={<SettingsPage />} />
          </Route>
        </Route>
      </Routes>
      <AlertContainer />
    </div>
  )
}

export default App
