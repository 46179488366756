import Tile from '../Tile'
import { CardWithBgImageLayoutProps } from './interface'
import './style.scss'

const CardWithBgImageLayout = ({ children, bgImgFileName }: CardWithBgImageLayoutProps) => {
  return (
    <section className="card-with-bg-layout">
      <img src={require(`assets/images/${bgImgFileName}`)} className="card-with-bg-layout__bg" />
      <Tile className="card-with-bg-layout__card" shadow="normal">
        {children}
      </Tile>
    </section>
  )
}

export default CardWithBgImageLayout
