import RewardBanner from 'components/atoms/RewardBanner'
import RewardFilters from 'components/atoms/RewardFilters'
import { RewardHeaderProps } from './interface'
import './style.scss'

const RewardHeader = ({ rewardCount }: RewardHeaderProps) => (
  <div className="reward-header">
    <RewardBanner />
    <RewardFilters rewardCount={rewardCount} />
  </div>
)

export default RewardHeader
