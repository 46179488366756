import HighlitedArticleCard from 'components/molecules/HighlitedArticleCard'
import { HighlitedArticleProps } from './interface'
import './style.scss'

const HighlitedArticle = ({ article }: HighlitedArticleProps) => {
  return (
    <div className="highlited-article">
      <img
        className="highlited-article__img"
        src={article.headerImage}
        alt="Zdjęcie wyróżnionego artykułu"
      />
      <HighlitedArticleCard {...article} />
    </div>
  )
}

export default HighlitedArticle
