import DescriptionSection from './partials/sections/DescriptionSection'
import ExpansionSection from './partials/sections/ExpansionSection'
import IconsSection from './partials/sections/IconsSection'
import IntroductionSection from './partials/sections/IntroductionSection'
import LandingSection from './partials/sections/LandingSection'
import MetricsSection from './partials/sections/MetricsSection'
import SummarySection from './partials/sections/SummarySection'

const HomePage = () => {
  return (
    <>
      <LandingSection />
      <IntroductionSection />
      <DescriptionSection />
      <MetricsSection />
      <ExpansionSection />
      <IconsSection />
      <SummarySection />
    </>
  )
}

export default HomePage
