import ArticleTypeTag from 'components/atoms/ArticleTypeTag'
import LineSeparator from 'components/atoms/LineSeparator'
import Tile from 'components/atoms/Tile'
import CardFooter from '../CardFooter'
import { HighlitedArticleCardProps } from './interface'
import parse from 'html-react-parser'

import './style.scss'
import { PATHS } from 'utils/consts'
import { generatePath } from 'react-router-dom'

const HighlitedArticleCard = ({ id, title, description, createdAt }: HighlitedArticleCardProps) => {
  return (
    <Tile className="highlited-article-card">
      <ArticleTypeTag orientation="top">Artykuł</ArticleTypeTag>
      <div className="highlited-article-card__content-wrapper">
        <h3 className="content-wrapper__title">{title}</h3>
        <LineSeparator />
        <div className="content-wrapper__description">{parse(description)}</div>
      </div>
      <CardFooter
        className="highlited-article-card__footer"
        text={createdAt}
        link={{
          text: 'Zobacz więcej',
          redirectionPath: generatePath(PATHS.articleDetails, { id })
        }}
      />
    </Tile>
  )
}

export default HighlitedArticleCard
