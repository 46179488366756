import classNames from 'classnames'
import parse from 'html-react-parser'
import { EArticleSectionStyleType } from 'models/articleSectionStyleType'
import { ArticleSectionProps } from './interface'
import './style.scss'

const ArticleSection = ({ content, media, mediaExtension, style }: ArticleSectionProps) => {
  const renderMedia = () => {
    if (!media) return

    switch (mediaExtension) {
      case 'mp4':
      case 'webm':
        return (
          <video className="article-section__media" controls>
            <source src={media} type={`video/${mediaExtension}`} />
          </video>
        )
      case 'mp3':
        return (
          <audio className="article-section__media" controls>
            <source src={media} type="audio/mpeg" />
          </audio>
        )
      default:
        return <img className="article-section__media" src={media} />
    }
  }

  return (
    <div className="article-section">
      <div
        className={classNames('article-section__content', {
          'article-section__content--normal': style === EArticleSectionStyleType.NormalText,
          'article-section__content--paragraph': style === EArticleSectionStyleType.Paragraph
        })}>
        {parse(content)}
      </div>
      {renderMedia()}
    </div>
  )
}

export default ArticleSection
