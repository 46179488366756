import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LOCAL_STORAGE } from 'utils/consts'
import { SaveCandidateHashRouteProps } from './interface'

const SaveCandidateHashRoute = ({ redirectionPath }: SaveCandidateHashRouteProps) => {
  const navigate = useNavigate()
  const { hash } = useParams()

  useEffect(() => {
    if (hash) {
      localStorage.setItem(LOCAL_STORAGE.candidateHash, hash)
    }

    navigate(redirectionPath, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div />
}

export default SaveCandidateHashRoute
