import Button from 'components/atoms/Button'
import Input from 'components/molecules/Input'
import PhoneField from 'components/molecules/PhoneField'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { valid } from 'utils/Validators/validators'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import { AddShippingDataFormProps } from './interface'
import 'pages/SettingsPage/partials/forms/style.scss'
import { IShippingData } from 'models/shippingData'
import { useContext } from 'react'
import { UserContext } from 'providers/user/context'
import { removeCountryCode } from 'utils/removeCountryCode'
import VoivodeshipSelect from 'components/molecules/VoivodeshipSelect'
import { handleApiResponse } from 'utils/handleApiResponse'

const AddShippingDataForm = ({ createShippingData, closeForm }: AddShippingDataFormProps) => {
  const { userData } = useContext(UserContext)
  const methods = useForm<IShippingData>()

  const onSubmit = async (formValues: FieldValues) => {
    const {
      fullName,
      phoneNumber,
      firstAddressLine,
      secondaryAddressLine,
      city,
      voivodeship,
      postCode
    } = formValues

    const response = await createShippingData({
      fullName,
      phoneNumber,
      firstAddressLine,
      secondaryAddressLine,
      city,
      voivodeship,
      postCode,
      isDefault: true
    })

    handleApiResponse(response, closeForm, methods.setError)
  }

  return (
    <FormProvider {...methods}>
      <form className="settings-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Input
          name="fullName"
          placeholder="Imię i nazwisko"
          defaultValue={`${userData.firstName} ${userData.lastName}`}
          validators={{ required: valid.required, ...validSchemas.name }}
        />
        <PhoneField
          required
          defaultValue={userData.phoneNumber ? removeCountryCode(userData.phoneNumber) : undefined}
        />
        <Input
          name="firstAddressLine"
          placeholder="Pierwsza linia adresu"
          validators={{ required: valid.required }}
        />
        <Input name="secondaryAddressLine" placeholder="Druga linia adresu" />
        <Input name="city" placeholder="Miasto" validators={validSchemas.locationName} />
        <VoivodeshipSelect required />
        <Input name="postCode" placeholder="Kod pocztowy" validators={validSchemas.postCode} />

        <div className="settings-form__footer">
          <Button className="settings-form__btn" type="submit">
            Zapisz
          </Button>
          <Button className="settings-form__btn btn--outline" onClick={closeForm}>
            Anuluj
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default AddShippingDataForm
