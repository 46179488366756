import Tile from 'components/atoms/Tile'
import SettingsTileHeader from 'components/molecules/SettingsTileHeader'
import SettingsTileRecord from 'components/molecules/SettingsTileRecord'
import useFiscalData from 'hooks/useFiscalData'
import AddFiscalDataForm from 'pages/SettingsPage/partials/forms/AddFiscalDataForm'
import BankModal from 'pages/SettingsPage/partials/modals/FiscalData/BankModal'
import BankNumberModal from 'pages/SettingsPage/partials/modals/FiscalData/BankNumberModal'
import CityModal from 'pages/SettingsPage/partials/modals/FiscalData/CityModal'
import CommuneModal from 'pages/SettingsPage/partials/modals/FiscalData/CommuneModal'
import CountryModal from 'pages/SettingsPage/partials/modals/FiscalData/CountryModal'
import CountyModal from 'pages/SettingsPage/partials/modals/FiscalData/CountyModal'
import DateOfBirthModal from 'pages/SettingsPage/partials/modals/FiscalData/DateOfBirthModal'
import FirstNameModal from 'pages/SettingsPage/partials/modals/FiscalData/FirstNameModal'
import FlatNumberModal from 'pages/SettingsPage/partials/modals/FiscalData/FlatNumberModal'
import HouseNumberModal from 'pages/SettingsPage/partials/modals/FiscalData/HouseNumberModal'
import IdentifierModal from 'pages/SettingsPage/partials/modals/FiscalData/IdentifierModal'
import LastNameModal from 'pages/SettingsPage/partials/modals/FiscalData/LastNameModal'
import MiddleNameModal from 'pages/SettingsPage/partials/modals/FiscalData/MiddleNameModal'
import PostCode from 'pages/SettingsPage/partials/modals/FiscalData/PostCode'
import PostOfficeModal from 'pages/SettingsPage/partials/modals/FiscalData/PostOfficeModal'
import StreetModal from 'pages/SettingsPage/partials/modals/FiscalData/StreetModal'
import TaxOfficeModal from 'pages/SettingsPage/partials/modals/FiscalData/TaxOfficeModal'
import VoivodeshipModal from 'pages/SettingsPage/partials/modals/FiscalData/VoivodeshipModal'

import { useModals } from 'providers/modals/context'
import { useState } from 'react'
import { getIdentifierOption } from 'utils/getIdentifierOption'
import { getTaxOfficeOption } from 'utils/getTaxOfficeOption'
import { getVoivodeship } from 'utils/getVoivodeship'
import { isObjectEmpty } from 'utils/isObjectEmpty'
import { FiscalDataProps } from './interface'

const FiscalData = ({ className }: FiscalDataProps) => {
  const { fiscalData, createFiscalData, updateFiscalData } = useFiscalData()
  const { openModal } = useModals()

  const [showForm, setShowForm] = useState(false)

  return (
    <Tile id="fiscal-data" className={className} shadow="light">
      <SettingsTileHeader
        title="Dane podatkowe"
        button={
          (!fiscalData || (fiscalData && isObjectEmpty(fiscalData))) && !showForm
            ? { text: 'Dodaj', onClick: () => setShowForm(true) }
            : undefined
        }
      />
      {fiscalData && !isObjectEmpty(fiscalData) ? (
        <>
          <SettingsTileRecord
            label="Imię"
            value={fiscalData.firstName}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <FirstNameModal
                    action="change"
                    defaultValue={fiscalData.firstName}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Drugie imię"
            value={fiscalData.middleName}
            button={
              !fiscalData.middleName
                ? {
                    text: 'Dodaj',
                    onClick: () =>
                      openModal(
                        <MiddleNameModal action="add" updateFiscalData={updateFiscalData} />
                      )
                  }
                : {
                    text: 'Zmień',
                    onClick: () =>
                      openModal(
                        <MiddleNameModal
                          action="change"
                          defaultValue={fiscalData.middleName}
                          updateFiscalData={updateFiscalData}
                        />
                      )
                  }
            }
          />
          <SettingsTileRecord
            label="Nazwisko"
            value={fiscalData.lastName}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <LastNameModal
                    action="change"
                    defaultValue={fiscalData.lastName}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Data urodzenia"
            value={fiscalData.dateOfBirth}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <DateOfBirthModal
                    action="change"
                    defaultValue={fiscalData.dateOfBirth}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Identyfikator"
            value={fiscalData.nip || fiscalData.pesel || undefined}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <IdentifierModal
                    action="change"
                    defaultValue={fiscalData.nip || fiscalData.pesel || undefined}
                    defaultOption={getIdentifierOption(fiscalData.nip ? 'nip' : 'pesel')}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Kraj"
            value={fiscalData.country}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <CountryModal
                    action="change"
                    defaultValue={fiscalData.country}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Województwo"
            value={getVoivodeship(fiscalData.voivodeship)?.label}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <VoivodeshipModal
                    action="change"
                    defaultOption={getVoivodeship(fiscalData.voivodeship)}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Powiat"
            value={fiscalData.county}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <CountyModal
                    action="change"
                    defaultValue={fiscalData.county}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Gmina"
            value={fiscalData.commune}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <CommuneModal
                    action="change"
                    defaultValue={fiscalData.commune}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Miasto"
            value={fiscalData.city}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <CityModal
                    action="change"
                    defaultValue={fiscalData.city}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Kod pocztowy"
            value={fiscalData.postCode}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <PostCode
                    action="change"
                    defaultValue={fiscalData.postCode}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Ulica"
            value={fiscalData.street}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <StreetModal
                    action="change"
                    defaultValue={fiscalData.street}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Numer domu"
            value={fiscalData.houseNumber}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <HouseNumberModal
                    action="change"
                    defaultValue={fiscalData.houseNumber}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Numer mieszkania"
            value={fiscalData.flatNumber}
            button={
              !fiscalData.flatNumber
                ? {
                    text: 'Dodaj',
                    onClick: () =>
                      openModal(
                        <FlatNumberModal action="add" updateFiscalData={updateFiscalData} />
                      )
                  }
                : {
                    text: 'Zmień',
                    onClick: () =>
                      openModal(
                        <FlatNumberModal
                          action="change"
                          defaultValue={fiscalData.flatNumber}
                          updateFiscalData={updateFiscalData}
                        />
                      )
                  }
            }
          />
          <SettingsTileRecord
            label="Poczta"
            value={fiscalData.postOffice}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <PostOfficeModal
                    action="change"
                    defaultValue={fiscalData.postOffice}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Urząd podatkowy"
            value={`${fiscalData.taxOffice.name}`}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <TaxOfficeModal
                    action="change"
                    defaultOption={getTaxOfficeOption(fiscalData.taxOffice)}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Nazwa banku"
            value={`${fiscalData.bank}`}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <BankModal
                    action="change"
                    defaultValue={fiscalData.bank}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
          <SettingsTileRecord
            label="Numer rachunku"
            value={`${fiscalData.bankNumber}`}
            button={{
              text: 'Zmień',
              onClick: () =>
                openModal(
                  <BankNumberModal
                    action="change"
                    defaultValue={fiscalData.bankNumber}
                    updateFiscalData={updateFiscalData}
                  />
                )
            }}
          />
        </>
      ) : (
        <>
          {showForm && (
            <AddFiscalDataForm
              createFiscalData={createFiscalData}
              closeForm={() => setShowForm(false)}
            />
          )}
        </>
      )}
    </Tile>
  )
}

export default FiscalData
