import { RewardCardTileProps } from './interface'
import './style.scss'

const RewardCardTile = ({ children, onCardClick }: RewardCardTileProps) => {
  return (
    <button className="reward-card-tile" onClick={onCardClick}>
      {children}
    </button>
  )
}

export default RewardCardTile
