import { IArticleDetails } from 'models/articleDetails'
import { formatISODate } from 'utils/formatISODate'

export const transformArticleDetailsResponseData = (
  articleDetails: IArticleDetails
): IArticleDetails => {
  articleDetails.createdAt = formatISODate(articleDetails.createdAt, 'dd MMMM yyyy')
  articleDetails.updatedAt = formatISODate(articleDetails.updatedAt, 'dd MMMM yyyy')

  return articleDetails
}
