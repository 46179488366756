import ArticleAttachmentItem from '../ArticleAttachmentItem'
import { ArticleAttachmentListProps } from './interface'
import './style.scss'

const ArticleAttachmentList = ({ attachments }: ArticleAttachmentListProps) => {
  return (
    <div className="article-attachment-list">
      <h3 className="article-attachment-list__label">Pliki do pobrania</h3>
      <div className="article-attachment-list__items">
        {attachments.map((attachment, index) => (
          <ArticleAttachmentItem key={index} attachment={attachment} />
        ))}
      </div>
    </div>
  )
}

export default ArticleAttachmentList
