import Input from 'components/molecules/Input'
import PhoneField from 'components/molecules/PhoneField'
import VoivodeshipSelect from 'components/molecules/VoivodeshipSelect'
import Modal from 'components/organisms/Modal'
import { useModals } from 'providers/modals/context'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { getVoivodeship } from 'utils/getVoivodeship'
import { handleApiResponse } from 'utils/handleApiResponse'
import { removeCountryCode } from 'utils/removeCountryCode'
import { valid } from 'utils/Validators/validators'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import { ChangeShippingDataModalProps } from './interface'

const ChangeShippingDataModal = ({
  shippingData,
  updateShippingData
}: ChangeShippingDataModalProps) => {
  const methods = useForm()
  const { closeModal } = useModals()

  const formID = 'changeShippingDataModal'

  const onSubmit = async (formValues: FieldValues) => {
    const response = await updateShippingData({ ...formValues }, shippingData.id)

    handleApiResponse(response, closeModal, methods.setError)
  }

  return (
    <Modal title="Zmień dane do wysyłki" buttonText="Zapisz" formID={formID}>
      <FormProvider {...methods}>
        <form id={formID} className="modal__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            name="fullName"
            placeholder="Imię i nazwisko"
            defaultValue={shippingData.fullName}
            validators={{ required: valid.required, ...validSchemas.name }}
          />
          <PhoneField required defaultValue={removeCountryCode(shippingData.phoneNumber)} />
          <Input
            name="firstAddressLine"
            placeholder="Pierwsza linia adresu"
            defaultValue={shippingData.firstAddressLine}
            validators={{ required: valid.required }}
          />
          <Input
            name="secondaryAddressLine"
            placeholder="Druga linia adresu"
            defaultValue={shippingData.secondaryAddressLine}
          />
          <Input
            name="city"
            placeholder="Miasto"
            defaultValue={shippingData.city}
            validators={validSchemas.locationName}
          />
          <VoivodeshipSelect required defaultOption={getVoivodeship(shippingData.voivodeship)} />
          <Input
            name="postCode"
            placeholder="Kod pocztowy"
            defaultValue={shippingData.postCode}
            validators={validSchemas.postCode}
          />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default ChangeShippingDataModal
