import { useFormContext } from 'react-hook-form'

import './style.scss'
import { InviteFormData } from 'pages/InvitePage/interface'
import { composeDate } from './utils'
import useCandidateSurvey from 'hooks/useCandidateSurvey'
import { InviteDateProps } from './interface'
import { useState } from 'react'
import { DatepickerType } from 'components/atoms/Datepicker/enums'
import Datepicker from 'components/atoms/Datepicker'
import Button from 'components/atoms/Button'
import Controller from 'components/atoms/Controller'
import RegionSelect from '../RegionSelect'

const inputsNames = ['region', 'date', 'time']

const InviteDate = ({ setIsSubmitSuccessful }: InviteDateProps) => {
  const methods = useFormContext<InviteFormData>()
  const { sendData } = useCandidateSurvey()
  const [showError, setShowError] = useState(false)

  const onSubmit = async (data: InviteFormData) => {
    try {
      const { date, time, ...rest } = data
      const dataToApi = { ...rest, preferredMeetingTime: composeDate(date, time).toISOString() }

      const res = await sendData(dataToApi)

      if (!res.succeed) {
        setShowError(true)

        return
      }

      setIsSubmitSuccessful(true)
    } catch (err) {
      console.error(err)
    }
  }

  const modifiedFields = Object.keys({
    ...methods.formState.touchedFields,
    ...methods.formState.dirtyFields
  })
  const isButtonEnabled = inputsNames.every((fieldName) => modifiedFields.includes(fieldName))

  return (
    <div className="invite-date">
      <h1 className="invite-date__title">
        Zaproponuj miejsce <br /> i czas spotkania
      </h1>
      {showError && (
        <p className="invite-date__error">Coś poszło nie tak. Prosimy spróbować ponownie.</p>
      )}
      <div className="invite-date__inputs">
        <RegionSelect required selectedRegionValue={methods.getValues('region')} />
        <Controller
          name="date"
          elementProps={{
            type: DatepickerType.Date,
            required: true,
            name: 'date',
            initialValue: methods.getValues('date')
          }}
          Element={Datepicker}
        />
        <Controller
          name="time"
          elementProps={{
            type: DatepickerType.Time,
            required: true,
            name: 'time',
            initialValue: methods.getValues('time')
          }}
          Element={Datepicker}
        />
      </div>
      <div className="invite-date__action">
        <Button
          form="inviteForm"
          className="invite-date__action-btn"
          onClick={methods.handleSubmit(onSubmit)}
          disable={!isButtonEnabled}>
          Potwierdź
        </Button>
      </div>
    </div>
  )
}

export default InviteDate
