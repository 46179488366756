import classNames from 'classnames'
import useRouteChange from 'hooks/useRouteChange'
import { MetricProps } from './interface'
import './style.scss'

const Metric = ({ className, value, content, link }: MetricProps) => {
  const { changeRoute } = useRouteChange()

  return (
    <div className={classNames('metric', className)}>
      <h1 className="metric__value">{value}</h1>
      <p className="metric__content">{content}</p>

      {link.text && (
        <span className="metric__link" onClick={() => link.href && changeRoute(link.href)}>
          {link.text}
        </span>
      )}
    </div>
  )
}

export default Metric
