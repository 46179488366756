import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'

const useCustomProfileBgImg = () => {
  const { data: profileBgImg } = useData<string>(ENDPOINTS.customProfileBgImg, {
    dataLocation: 'profileBackground'
  })

  return { profileBgImg }
}

export default useCustomProfileBgImg
