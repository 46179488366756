import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'
import { IHomePageIntroduction } from '../models/homePageIntroduction'

const useHomePageIntroduction = () => {
  const { data, ...rest } = useData<IHomePageIntroduction>(ENDPOINTS.homePageIntroduction)

  return { data, ...rest }
}

export default useHomePageIntroduction
