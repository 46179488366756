import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import Button from '../../atoms/Button'
import { ScrollToTopButtonProps } from './inerface'
import './style.scss'

const ScrollToTopButton = ({ className }: ScrollToTopButtonProps) => {
  const [hideButton, setHideButton] = useState(false)

  const onClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.onscroll = () => {
      setHideButton(window.scrollY < 50)
    }
  }, [])

  return (
    <Button
      className={classNames('scroll-to-top-btn', className, {
        'scroll-to-top-btn--hide': hideButton
      })}
      onClick={onClick}>
      <FontAwesomeIcon icon={faAngleUp} />
    </Button>
  )
}

export default ScrollToTopButton
