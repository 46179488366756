import Button from 'components/atoms/Button'
import Input from 'components/molecules/Input'
import { IPayoutData } from 'models/payoutData'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { handleApiResponse } from 'utils/handleApiResponse'
import { validSchemas } from 'utils/Validators/validatorsSchemas'
import { AddPayoutDataFormProps } from './interface'

const AddPayoutDataForm = ({ createPayoutData, closeForm }: AddPayoutDataFormProps) => {
  const methods = useForm<Omit<IPayoutData, 'id'>>()

  const onSubmit = async (formValues: Omit<IPayoutData, 'id'>) => {
    const response = await createPayoutData(formValues)

    handleApiResponse(response, closeForm, methods.setError)
  }

  return (
    <FormProvider {...methods}>
      <form className="settings-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Input
          name="bankNumber"
          placeholder="Numer rachunku"
          validators={validSchemas.bankNumber}
        />

        <div className="settings-form__footer">
          <Button className="settings-form__btn" type="submit">
            Zapisz
          </Button>
          <Button className="settings-form__btn btn--outline" onClick={closeForm}>
            Anuluj
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default AddPayoutDataForm
