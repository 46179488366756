import { DescriptionStepItemProps } from './interface'
import './style.scss'

const DescriptionStepItem = ({ title, content }: DescriptionStepItemProps) => {
  return (
    <li className="description-step-item">
      <h4 className="description-step-item__title">{title}</h4>
      <p className="description-step-item__content">{content}</p>
    </li>
  )
}

export default DescriptionStepItem
