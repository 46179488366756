import classNames from 'classnames'
import { ISelectOption } from 'models/selectOption'
import ReactSelect, { SingleValue } from 'react-select'
import ErrorMessage from '../ErrorMessage'
import { SelectProps } from './interface'
import './style.scss'

const Select = ({
  className,
  hideIndicators,
  options,
  placeholder,
  defaultOption,
  error,
  onChange,
  form
}: SelectProps) => {
  const handleChange = (option: SingleValue<ISelectOption>) => {
    onChange(option?.value || '')
  }

  return (
    <div className="select-wrapper">
      <ReactSelect
        className={classNames('select-wrapper__select', className, {
          'select-wrapper__select--invalid': error,
          'select-wrapper__select--hide-indicators': hideIndicators
        })}
        classNamePrefix={'select'}
        options={options}
        placeholder={placeholder}
        noOptionsMessage={() => 'Brak opcji'}
        onChange={handleChange}
        defaultValue={defaultOption}
        form={form}
      />
      {error?.message && <ErrorMessage message={error.message} />}
    </div>
  )
}

export default Select
