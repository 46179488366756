import RewardCardTile from 'components/atoms/RewardCardTile'
import PurchaseDetailsModal from 'components/organisms/PurchaseDetailsModal'
import RewardDetailsModal from 'components/organisms/RewardDetailsModal'
import { useModals } from 'providers/modals/context'
import RewardCardContent from '../RewardCardContent'
import RewardCardImage from '../RewardCardImage'
import { PurchasedRewardCardProps } from './interface'

const PurchasedRewardCard = ({ purchasedItem }: PurchasedRewardCardProps) => {
  const { openModal } = useModals()

  const { reward, pointsSpent } = purchasedItem
  const { title, shortDescription, payout, type, rewardImages } = reward
  const image = rewardImages?.[0]?.image
  const formattedPrice = payout ? `${pointsSpent} pkt / ${payout} zł` : `${pointsSpent} pkt`

  const openRewardDetailsModal = () => {
    openModal(<RewardDetailsModal data={reward} hidePurchaseButton={true} />)
  }

  const openPurchaseDetailsModal = () => {
    openModal(
      <PurchaseDetailsModal purchasedItem={purchasedItem} rewardFormattedPrice={formattedPrice} />
    )
  }

  return (
    <RewardCardTile onCardClick={openRewardDetailsModal}>
      <RewardCardImage rewardType={type} image={image} formattedPrice={formattedPrice} />
      <RewardCardContent
        title={title}
        shortDescription={shortDescription}
        formattedPrice={formattedPrice}
        buttonType="details"
        onButtonClick={openPurchaseDetailsModal}
      />
    </RewardCardTile>
  )
}

export default PurchasedRewardCard
