import { IFilledCandidateFormData, InviteFormData } from 'pages/InvitePage/interface'
import { UseFormReturn } from 'react-hook-form'

export const setCandidateDetails = (
  data: IFilledCandidateFormData,
  methods: UseFormReturn<InviteFormData, any>
) => {
  Object.entries(data).forEach(([key, value]) => {
    if (value === undefined || value === null || value === '') {
      return
    }
    methods.setValue(key as keyof InviteFormData, value, {
      shouldDirty: true,
      shouldTouch: true
    })
  })
}
