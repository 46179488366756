import Pagination from 'components/atoms/Pagination'
import CandidatesList from 'components/organisms/CandidatesList'
import CustomListing from 'components/molecules/CustomListing'
import useWindowMatchMedia from 'hooks/useWindowMatchMedia'
import { DashboardProps } from './interface'
import './style.scss'
import { BREAKPOINTS } from 'consts/breakpoints'

const Dashboard = ({ candidates, pageCount, isError, getCandidates }: DashboardProps) => {
  const [displayTableHeader] = useWindowMatchMedia(BREAKPOINTS.tablet, 'min')

  return (
    <>
      {isError ? (
        <div className="dashboard dashboard--no-data">
          <h3 className="dashboard__title">Wystąpił błąd podczas ładowania kandydatów</h3>
        </div>
      ) : (
        <>
          {candidates?.length ? (
            <div className="dashboard">
              <div className="dashboard__header">
                <CustomListing label="Sortuj według" defaultValue="Data rosnąco" />
                <CustomListing label="Pokaż status" defaultValue="Wszystkie" />
                {/* <Button className="btn--outline dashboard__btn">Pobierz CSV</Button> */}
              </div>
              <div className="dashboard__table">
                {displayTableHeader && (
                  <div className="table__header">
                    <strong>Data dodania</strong>
                    <strong>Imię</strong>
                    <strong>Nazwisko</strong>
                    <strong>Telefon</strong>
                    <strong>Email</strong>
                    <strong>Kategorie</strong>
                    <strong>Status</strong>
                    <strong>Punkty</strong>
                  </div>
                )}
                <CandidatesList candidates={candidates} />
              </div>

              {pageCount > 1 && <Pagination pageCount={pageCount} apiRequest={getCandidates} />}
            </div>
          ) : (
            <div className="dashboard dashboard--no-data">
              <h3 className="dashboard__title">Lista poleceń</h3>
              <p className="dashboard__subtitle">Twoja lista poleceń jest obecnie pusta</p>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Dashboard
