import Controller from 'components/atoms/Controller'
import Select from 'components/atoms/Select'
import useTaxOffices from 'hooks/useTaxOffices'
import { valid } from 'utils/Validators/validators'
import { TaxOfficeSelectProps } from './interface'

const TaxOfficeSelect = ({ required, defaultOption }: TaxOfficeSelectProps) => {
  const { taxOfficesOptions } = useTaxOffices()

  return (
    <Controller
      name="taxOffice"
      rules={{ required: required ? valid.required : false }}
      Element={Select}
      elementProps={{ options: taxOfficesOptions, placeholder: 'Urząd podatkowy', defaultOption }}
      defaultValue={defaultOption?.value}
    />
  )
}

export default TaxOfficeSelect
