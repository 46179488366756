import { RewardShopFiltersContextProvider } from 'providers/rewardShopFilters'
import RewardShopPageContent from './partials/components/RewardShopPageContent'

const RewardShopPage = () => {
  return (
    <RewardShopFiltersContextProvider>
      <RewardShopPageContent />
    </RewardShopFiltersContextProvider>
  )
}

export default RewardShopPage
