import { useEffect, useState } from 'react'
import LeadCategoryItem from 'components/molecules/LeadCategoryItem'
import { LeadCategoryListProps } from './interface'
import './style.scss'

const LeadCategoryList = ({ leadCategories, defaultValue, onChange }: LeadCategoryListProps) => {
  const [selectedCategories, setSelectedCategories] = useState<number[]>(defaultValue || [])

  const updateCategories = (value: number) => {
    if (selectedCategories.includes(value)) {
      const newList = selectedCategories.filter((item) => item !== value)
      setSelectedCategories(newList)
    } else {
      setSelectedCategories([...selectedCategories, value])
    }
  }

  const isSelected = (id: number): boolean => {
    return selectedCategories.includes(id)
  }

  useEffect(() => {
    onChange(selectedCategories)
  }, [onChange, selectedCategories])

  return (
    <ul className="lead-category-list">
      {leadCategories?.map((leadCategory) => (
        <LeadCategoryItem
          key={leadCategory.id}
          leadCategory={leadCategory}
          isSelected={isSelected(leadCategory.id)}
          onClick={updateCategories}
        />
      ))}
    </ul>
  )
}

export default LeadCategoryList
