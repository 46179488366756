import Button from 'components/atoms/Button'
import { generatePath, Link } from 'react-router-dom'
import { API_URL, ENDPOINTS, LOCAL_STORAGE, PATHS } from 'utils/consts'
import { MeetingConfirmedViewProps } from './interface'

const MeetingConfirmedView = ({ meetingInfo }: MeetingConfirmedViewProps) => {
  const { placeOfMeeting, date, time } = meetingInfo

  const candidateHash = localStorage.getItem(LOCAL_STORAGE.candidateHash)
  const QRCodeEndpoint = API_URL + generatePath(ENDPOINTS.meetingQR, { hash: candidateHash })

  return (
    <>
      <h1 className="meeting-page__card-title">Gotowe</h1>
      <p className="meeting-page__card-content meeting-page__card-content--light-color">
        {`Twoje spotkanie jest zaplanowane na ${date} o godzinie ${time} w placówce pod adresem
        ${placeOfMeeting}. Podczas spotkania, pokaż swój kod QR:`}
      </p>
      <img className="meeting-page__qr-code" src={QRCodeEndpoint} alt="QR code" />
      <div className="meeting-page__card-footer">
        <Link to={PATHS.home}>
          <Button className="meeting-page__card-btn btn--outline">Strona główna</Button>
        </Link>
      </div>
    </>
  )
}

export default MeetingConfirmedView
