import HeaderIcon1 from 'assets/icons/reward-header-icon-1.png'
import HeaderIcon2 from 'assets/icons/reward-header-icon-2.png'
import HeaderIcon3 from 'assets/icons/reward-header-icon-3.png'
import HeaderIcon4 from 'assets/icons/reward-header-icon-4.png'
import HeaderIcon5 from 'assets/icons/reward-header-icon-5.png'
import './style.scss'

const RewardBanner = () => (
  <div className="reward-banner">
    <header className="reward-banner__header">
      <h1 className="reward-banner__title">Polecaj, zbieraj punkty i wygrywaj!</h1>
    </header>
    <div className="reward-banner__content">
      <ul className="reward-banner__list">
        <li className="reward-banner__list-item">
          <img src={HeaderIcon1} alt="header icon" />
        </li>
        <li className="reward-banner__list-item">
          <img src={HeaderIcon2} alt="header icon" />
        </li>
        <li className="reward-banner__list-item">
          <img src={HeaderIcon3} alt="header icon" />
        </li>
        <li className="reward-banner__list-item">
          <img src={HeaderIcon4} alt="header icon" />
        </li>
        <li className="reward-banner__list-item">
          <img src={HeaderIcon5} alt="header icon" />
        </li>
      </ul>
    </div>
  </div>
)

export default RewardBanner
