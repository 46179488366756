import { IFiscalData } from 'models/fiscalData'
import { ENDPOINTS } from 'utils/consts'
import { transformFiscalDataRequestData } from 'utils/TransformRequestData/fiscalData'
import useData from './useData'

const useFiscalData = () => {
  const {
    data: fiscalData,
    createData: createFiscalData,
    updateData: updateFiscalData,
    ...rest
  } = useData<IFiscalData>(ENDPOINTS.fiscalData, {
    transformRequestData: transformFiscalDataRequestData
  })

  return { fiscalData, createFiscalData, updateFiscalData, ...rest }
}

export default useFiscalData
