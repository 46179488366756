import { valid } from './validators'

export const validSchemas = {
  name: {
    pattern: valid.namesPattern,
    minLength: valid.minLength(2),
    maxLength: valid.maxLength(50)
  },
  postCode: {
    required: valid.required,
    pattern: valid.postCodePattern
  },
  locationName: {
    required: valid.required,
    pattern: valid.textFieldPattern,
    minLength: valid.minLength(3),
    maxLength: valid.maxLength(40)
  },
  bankNumber: {
    required: valid.required,
    pattern: valid.bankNumberPattern
  },
  institutionName: {
    required: valid.required,
    pattern: valid.textFieldPattern,
    minLength: valid.minLength(3),
    maxLength: valid.maxLength(150)
  },
  buildingNumber: {
    pattern: valid.buildingNumberPattern,
    maxLength: valid.maxLength(10)
  },
  street: {
    minLength: valid.minLength(3),
    maxLength: valid.maxLength(100)
  }
}
