/* eslint-disable no-unused-vars */

export enum ECandidateStatus {
  NEW = 'new',
  NO_RESPONSE = 'no_response',
  CONFIRMED = 'confirmed',
  NON_COMPLIANT = 'non-compliant',
  INVITED = 'invited',
  AWAITING_MEETING = 'awaiting_meeting',
  RESIGNED = 'resigned',
  REJECTED = 'rejected',
  DROPPED_OUT = 'dropped_out',
  HIRED_SALE = 'hired/sale'
}
