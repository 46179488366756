import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'
import { IHomePageExpansion } from '../models/homePageExpansion'
import { IHomePageExpansionImage } from '../models/homePageExpansionImage'

const useHomePageExpansion = () => {
  const { data } = useData<IHomePageExpansion>(ENDPOINTS.homePageExpansion)
  const { data: image } = useData<IHomePageExpansionImage>(ENDPOINTS.homePageExpansionImage)

  return { data, image }
}

export default useHomePageExpansion
