import WithdrawDataView from 'components/atoms/WithdrawDataView'
import WithdrawNoData from 'components/atoms/WithdrawNoData'
import useFiscalData from 'hooks/useFiscalData'
import useShippingData from 'hooks/useShippingData'
import { ERewardType } from 'models/rewardType'
import { EWithdrawDataType } from 'models/withdrawDataType'
import { useModals } from 'providers/modals/context'
import { UserContext } from 'providers/user/context'
import { useContext, useEffect } from 'react'
import { isObjectEmpty } from 'utils/isObjectEmpty'
import { WithdrawModalProps } from './interface'
import usePayoutData from 'hooks/usePayoutData'
import usePurchaseReward from 'hooks/usePurchaseReward'
import { toast } from 'react-toastify'
import { IPurchaseReward } from 'models/requests/purchaseReward'
import SidewayModal from 'components/molecules/SidewayModal'

const WithdrawModal = ({
  rewardID,
  rewardType,
  rewardName,
  rewardFormattedPrice,
  rewardCost
}: WithdrawModalProps) => {
  const { closeAllModals } = useModals()
  const { userPoints, updateUserPoints } = useContext(UserContext)
  const { purchaseReward } = usePurchaseReward()
  const { fiscalData, createFiscalData, updateFiscalData } = useFiscalData()
  const { mainShippingData, getShippingData, createShippingData, updateShippingData } =
    useShippingData({
      dontFetchOnMount: true
    })
  const { mainPayoutData, getPayoutData, createPayoutData, updatePayoutData } = usePayoutData({
    dontFetchOnMount: true
  })

  const isRewardMonetary = rewardType === ERewardType.Monetary
  const isRewardPhysical = rewardType === ERewardType.Physical

  const isPurchaseNotPossible = () => {
    if (!fiscalData || isObjectEmpty(fiscalData)) {
      return true
    } else {
      if (isRewardMonetary) {
        if (!mainPayoutData) return true
      } else if (isRewardPhysical) {
        if (!mainShippingData) return true
      }
    }
    return false
  }

  const handlePurchase = async () => {
    let body: IPurchaseReward
    const reward = { reward: rewardID }

    if (isRewardMonetary) {
      body = { bankAccount: mainPayoutData?.id, ...reward }
    } else if (isRewardPhysical) {
      body = { shippingData: mainShippingData?.id, ...reward }
    } else {
      body = { ...reward }
    }

    const response = await purchaseReward(body, { preventDataRefreshAfterRequest: true })

    if (response.succeed) {
      updateUserPoints()
      closeAllModals()
      toast.success(`Kupiono nagrodę: ${rewardName} za ${rewardCost} punktów!`)
    }
  }

  useEffect(() => {
    if (isRewardMonetary) {
      getPayoutData()
    } else if (isRewardPhysical) {
      getShippingData()
    }
  }, [getPayoutData, getShippingData, isRewardMonetary, isRewardPhysical])

  return (
    <SidewayModal
      title="Wymień punkty na nagrody"
      productInfo={{ name: rewardName, price: rewardFormattedPrice }}
      footer={{
        title: 'Po aktywacji zostanie Ci:',
        content: userPoints ? `${userPoints - rewardCost} pkt` : ''
      }}
      button={{
        text: 'Aktywuj',
        disable: isPurchaseNotPossible(),
        onClick: handlePurchase
      }}>
      {isRewardMonetary && (
        <li className="sideway-modal__process-step">
          <h4 className="process-step__title">Dane do wypłaty</h4>
          {!mainPayoutData ? (
            <WithdrawNoData
              dataType={EWithdrawDataType.Payout}
              createPayoutData={createPayoutData}
            />
          ) : (
            <WithdrawDataView payoutData={{ data: mainPayoutData, update: updatePayoutData }} />
          )}
        </li>
      )}
      {isRewardPhysical && (
        <li className="sideway-modal__process-step">
          <h4 className="process-step__title">Dane do wysyłki</h4>
          {!mainShippingData ? (
            <WithdrawNoData
              dataType={EWithdrawDataType.Shipping}
              createShippingData={createShippingData}
            />
          ) : (
            <WithdrawDataView
              shippingData={{ data: mainShippingData, update: updateShippingData }}
            />
          )}
        </li>
      )}
      <li className="sideway-modal__process-step">
        <h4 className="process-step__title">Dane podatkowe</h4>
        {!fiscalData || isObjectEmpty(fiscalData) ? (
          <WithdrawNoData dataType={EWithdrawDataType.Tax} createFiscalData={createFiscalData} />
        ) : (
          <WithdrawDataView fiscalData={{ data: fiscalData, update: updateFiscalData }} />
        )}
      </li>
    </SidewayModal>
  )
}

export default WithdrawModal
