import Tile from '../Tile'
import { MetricTileProps } from './interface'
import './style.scss'

const MetricTile = ({ Icon, description, value }: MetricTileProps) => {
  return (
    <Tile className="metric-tile" shadow="light">
      <div className="metric-tile__icon-wrapper">
        <div className="metric-tile__icon">{Icon}</div>
      </div>
      <div className="metric-tile__content">
        <p className="metric-tile__description">{description}</p>
        <strong className="metric-tile__value">{value}</strong>
      </div>
    </Tile>
  )
}

export default MetricTile
