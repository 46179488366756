import { CandidateLeadCategoriesProps } from './interface'
import './style.scss'
import CategoryIcon from 'assets/icons/category.svg'

const CandidateLeadCategories = ({ leadCategories }: CandidateLeadCategoriesProps) => {
  return (
    <div className="candidate-lead-categories">
      {leadCategories.map((leadCategory) => (
        <img
          className="candidate-lead-categories__icon"
          key={leadCategory.id}
          src={leadCategory.icon || CategoryIcon}
        />
      ))}
      <div className="candidate-lead-categories__tooltip">
        {leadCategories.map((leadCategory, index) => (
          <p key={leadCategory.id}>
            {index + 1}. {leadCategory.category}
          </p>
        ))}
      </div>
    </div>
  )
}

export default CandidateLeadCategories
