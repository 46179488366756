import ArticleCardList from 'components/organisms/ArticleCardList'
import HighlitedArticle from 'components/organisms/HighlitedArticle'
import useArticles from './hooks/useArticles'
import './style.scss'

const EduPage = () => {
  const { highlitedArticle, restOfArticles } = useArticles()

  return (
    <div className="edu-page">
      {highlitedArticle && <HighlitedArticle article={highlitedArticle} />}
      {restOfArticles && <ArticleCardList articles={restOfArticles} />}
    </div>
  )
}

export default EduPage
