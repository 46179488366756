import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import useAuth from 'hooks/useAuth'
import { valid } from 'utils/Validators/validators'
import './style.scss'
import Input from 'components/molecules/Input'
import AuthCard from 'components/molecules/AuthCard'
import useReadLocationStateOnce from 'hooks/useReadLocationStateOnce'

const ForgotPasswordPage = () => {
  const { isLoading, resetPassword } = useAuth()
  const methods = useForm({ mode: 'onChange' })
  const invalidToken = useReadLocationStateOnce<boolean>('invalidToken')
  const formID = 'forgotPasswordForm'

  const onSubmit = (formValues: FieldValues): void => {
    const { email } = formValues

    resetPassword(email)
  }

  const { isDirty, isValid, isSubmitSuccessful } = methods.formState

  const forgotPasswordContent = !isSubmitSuccessful
    ? {
        description:
          'Aby zresetować hasło, wprowadź poniżej swoją nazwę użytkownika lub adres e-mail. Jeżeli uda nam się znaleźć Twoje dane w bazie danych, zostanie wysłana wiadomość na Twój adres e-mail z instrukcją jak uzyskać ponownie dostęp.',
        submitButton: {
          text: 'Zresetuj hasło',
          formID,
          disabled: isLoading || !isDirty || !isValid
        }
      }
    : {
        description:
          'Jeśli wpisałeś właściwą nazwę użytkownika lub adres e-mail, na twoją skrzynkę zostanie wysłana wiadomość. \n \n Znajdziesz w niej łatwą instrukcję zmiany hasła. W razie problemów skontaktuj się z administratorem serwisu.'
      }

  return (
    <AuthCard title="Nie pamiętasz hasła?" {...{ ...forgotPasswordContent }}>
      {invalidToken && !isSubmitSuccessful && (
        <p className="forgot-password-message">
          Twój link do zmiany hasła wygasł. Wypełnij formularz ponownie.
        </p>
      )}
      {!isSubmitSuccessful ? (
        <FormProvider {...methods}>
          <form
            className="forgot-password-form"
            id={formID}
            onSubmit={methods.handleSubmit(onSubmit)}>
            <Input
              hideErrors
              name="email"
              placeholder="Email"
              validators={{ required: valid.required, pattern: valid.emailPattern }}
            />
          </form>
        </FormProvider>
      ) : (
        <div className="forgot-password-action">
          <a href="/" className="forgot-password-action__btn btn">
            Gotowe
          </a>
        </div>
      )}
    </AuthCard>
  )
}

export default ForgotPasswordPage
