import classNames from 'classnames'
import React, { useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import pl from 'date-fns/locale/pl'
import 'react-datepicker/dist/react-datepicker.css'
import { DatepickerProps } from './interface'
import './style.scss'

const Datepicker = ({ name, type, required, initialValue, onChange }: DatepickerProps) => {
  const [startDate, setStartDate] = useState<Date | null>(initialValue ?? null)
  registerLocale('pl', pl)

  const handleChange = (date: Date | null) => {
    onChange(date)
    setStartDate(date)
  }

  const inputLabel = type === 'date' ? 'Data' : 'Czas'
  const typeSpecificProps =
    type === 'time'
      ? {
          showTimeSelect: true,
          showTimeSelectOnly: true,
          timeIntervals: 15,
          dateFormat: 'HH:mm',
          timeFormat: 'HH:mm'
        }
      : {
          dateFormat: 'dd.MM.yyyy'
        }

  const CustomInput = (
    props: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <div className="date-picker__input-wrapper">
        <input
          {...props}
          name={name}
          className={classNames('date-picker__input', {
            'date-picker__input--required': required,
            'date-picker__input--filled': startDate
          })}
        />
        <label htmlFor={name} className="date-picker__input-label">
          {inputLabel}
        </label>
      </div>
    )
  }

  return (
    <div className="date-picker">
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        customInput={React.createElement(React.forwardRef(CustomInput))}
        minDate={new Date()}
        locale="pl"
        {...typeSpecificProps}
      />
    </div>
  )
}

export default Datepicker
