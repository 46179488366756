import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'

const useRegistrationTermsAndConditions = () => {
  const { data: termsAndConditions } = useData<string>(ENDPOINTS.registrationTermsAndConditions, {
    dataLocation: 'registrationTermsAndConditions'
  })

  return { termsAndConditions }
}

export default useRegistrationTermsAndConditions
