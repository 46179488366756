import DescriptiveModal from 'components/organisms/DescriptiveModal'
import useRecommendationTermsAndConditions from 'pages/ProfilePage/hook/useRecommendationTermsAndConditions'

const RecommendationTermsAndConditionsModal = () => {
  const { termsAndConditions } = useRecommendationTermsAndConditions()

  return <DescriptiveModal title="Zasady i warunki">{termsAndConditions}</DescriptiveModal>
}

export default RecommendationTermsAndConditionsModal
