import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'

const useCustomLogo = () => {
  const { data: logo } = useData<string>(ENDPOINTS.customLogo, { dataLocation: 'logo' })

  return { logo }
}

export default useCustomLogo
