import ChangePayoutDataModal from 'components/templates/ChangePayoutDataModal'
import ChangeShippingDataModal from 'components/templates/ChangeShippingDataModal'
import ChangeFiscalDataModal from 'pages/RewardShopPage/partials/modals/ChangeFiscalDataModal'
import { useModals } from 'providers/modals/context'
import { getVoivodeship } from 'utils/getVoivodeship'
import { WithdrawDataViewProps } from './interface'
import './style.scss'

const WithdrawDataView = ({ fiscalData, shippingData, payoutData }: WithdrawDataViewProps) => {
  const { openModal } = useModals()

  const renderData = () => {
    if (fiscalData) {
      return (
        <>
          <p>{`${fiscalData.data.firstName} ${fiscalData.data.lastName}`}</p>
          <p>
            {fiscalData.data.flatNumber
              ? `${fiscalData.data.street} ${fiscalData.data.houseNumber}/${fiscalData.data.flatNumber}`
              : `${fiscalData.data.street} ${fiscalData.data.houseNumber}`}
          </p>
          <p>{`${fiscalData.data.postCode} ${fiscalData.data.city}`}</p>
          <p>{`Woj. ${getVoivodeship(fiscalData.data.voivodeship || '')?.label}`}</p>
          <p>
            {fiscalData.data.nip ? `NIP ${fiscalData.data.nip}` : `PESEL ${fiscalData.data.pesel}`}
          </p>
        </>
      )
    } else if (shippingData) {
      return (
        <>
          <p>{shippingData.data.fullName}</p>
          <p>{shippingData.data.phoneNumber}</p>
          <p>
            {shippingData.data.secondaryAddressLine
              ? `${shippingData.data.firstAddressLine}, ${shippingData.data.secondaryAddressLine}`
              : shippingData.data.firstAddressLine}
          </p>
          <p>{`${shippingData.data.postCode} ${shippingData.data.city}`}</p>
          <p>{`Woj. ${getVoivodeship(shippingData.data.voivodeship || '')?.label}`}</p>
        </>
      )
    } else if (payoutData) {
      return <p>{payoutData.data.bankNumber}</p>
    }
  }

  const handleChangeButton = () => {
    if (fiscalData) {
      openModal(
        <ChangeFiscalDataModal fiscalData={fiscalData.data} updateFiscalData={fiscalData.update} />
      )
    } else if (shippingData) {
      openModal(
        <ChangeShippingDataModal
          shippingData={shippingData.data}
          updateShippingData={shippingData.update}
        />
      )
    } else if (payoutData) {
      openModal(
        <ChangePayoutDataModal payoutData={payoutData.data} updatePayoutData={payoutData.update} />
      )
    }
  }

  return (
    <div className="withdraw-data-view">
      <span className="withdraw-data-view__edit-btn" onClick={handleChangeButton}>
        Zmień
      </span>

      {renderData()}
    </div>
  )
}

export default WithdrawDataView
