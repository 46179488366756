import { CandidateListProps } from './interface'
import CandidatesItem from '../CandidatesItem'

const CandidatesList = ({ candidates }: CandidateListProps) => {
  return (
    <>
      {candidates?.map((candidate, index) => (
        <CandidatesItem key={index} candidate={candidate} />
      ))}
    </>
  )
}

export default CandidatesList
