import { useState } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import useAuth from 'hooks/useAuth'
import { valid } from 'utils/Validators/validators'
import './style.scss'
import Input from 'components/molecules/Input'
import AuthCard from 'components/molecules/AuthCard'
import { comparePasswords } from 'utils/comparePasswords'
import { getUrlParams } from 'utils/getUrlParams'
import { useChangePasswordPage } from './hooks'

const ChangePasswordPage = () => {
  const { changePassword } = useAuth()
  const methods = useForm()
  const [displayError, setDisplayError] = useState(false)
  const { isLoading } = useChangePasswordPage()

  const formID = 'changePasswordForm'
  const currentPassword = methods.watch('password')
  const confirmPassword = methods.watch('confirmPassword')

  const onSubmit = async (data: FieldValues) => {
    const { password } = data
    const token = getUrlParams('token')

    if (!token) return

    try {
      const res = await changePassword({ password, token })

      if (res?.succeed) {
        setDisplayError(!res.succeed)
      }
    } catch (error) {
      setDisplayError(true)
    }
  }

  // TODO: Add loader

  return (
    <AuthCard
      title="Zmiana hasła"
      submitButton={{ text: 'Zapisz', formID, disabled: isLoading || !confirmPassword }}>
      {displayError && (
        <p className="change-password-error">
          Twój link do zmiany hasła wygasł. Wypełnij formularz ponownie.
        </p>
      )}
      <FormProvider {...methods}>
        <form
          className="change-password-form"
          id={formID}
          onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            name="password"
            type="password"
            placeholder="Hasło"
            validators={{ required: valid.required, pattern: valid.passwordPattern }}
          />

          <Input
            name="confirmPassword"
            type="password"
            placeholder="Powtórz hasło"
            validators={{
              required: valid.required,
              validate: (value) => comparePasswords(currentPassword, value)
            }}
          />
        </form>
      </FormProvider>
    </AuthCard>
  )
}

export default ChangePasswordPage
