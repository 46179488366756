import './style.scss'
import LineSeparator from 'components/atoms/LineSeparator'
import IntroductionStep from '../../components/IntroductionStep'
import useHomePageIntroduction from 'pages/HomePage/hooks/useHomePageIntroduction'
import parse from 'html-react-parser'

const IntroductionSection = () => {
  const { data } = useHomePageIntroduction()

  return (
    <section className="introduction-section">
      <div className="introduction-section__description">
        <h1 className="description__title">{data?.introductionTitle}</h1>
        <LineSeparator />
        <p className="description__content">{parse(data?.introductionContent || '')}</p>
      </div>
      <div className="introduction-section__steps">
        {data?.introductionSteps.map((step, index) => (
          <IntroductionStep
            key={index}
            index={index + 1}
            title={step.title}
            content={step.content}
            button={{ text: step.buttonText, link: step.buttonLink }}
          />
        ))}
      </div>
    </section>
  )
}

export default IntroductionSection
