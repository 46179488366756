import CardWithBgImageLayout from 'components/atoms/CardWithBgImageLayout'
import useMeeting from 'hooks/useMeeting'
import { EMeetingStatus } from 'models/meetingStatus'
import MeetingCanceledView from './partials/components/MeetingCanceledView'
import MeetingConfirmedView from './partials/components/MeetingConfirmedView'
import MeetingScheduledView from './partials/components/MeetingScheduledView'
import './style.scss'

const MeetingPage = () => {
  const { meetingInfo, updateMeetingStatus } = useMeeting()

  const renderMeetingView = () => {
    switch (meetingInfo?.status) {
      case EMeetingStatus.SCHEDULED:
        return (
          <MeetingScheduledView
            meetingInfo={meetingInfo}
            updateMeetingStatus={updateMeetingStatus}
          />
        )
      case EMeetingStatus.CONFIRMED:
        return <MeetingConfirmedView meetingInfo={meetingInfo} />
      case EMeetingStatus.CANCELLED:
      case EMeetingStatus.DONE:
        return <MeetingCanceledView />
    }
  }

  return (
    <CardWithBgImageLayout bgImgFileName="default-meeting-bg-img.png">
      {renderMeetingView()}
    </CardWithBgImageLayout>
  )
}

export default MeetingPage
