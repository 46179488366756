import useCustomAuthBgImg from './hooks/useCustomAuthBgImg'
import './style.scss'

const AuthBackground = () => {
  const { authBgImg } = useCustomAuthBgImg()

  return (
    <div className="auth-bg">
      <img
        src={authBgImg || require('assets/images/default-auth-bg-img.jpg')}
        className="auth-bg__image"
      />
      <div className="auth-bg__image-mask" />
    </div>
  )
}

export default AuthBackground
