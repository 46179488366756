import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from 'providers/user/context'
import { PATHS } from 'utils/consts'
import DefaultLogo from 'assets/icons/default-logo.svg'
import './style.scss'
import Button from 'components/atoms/Button'
import NavDropdown from 'components/molecules/NavDropdown'
import MedalIcon from 'components/atoms/MedalIcon'
import useCustomLogo from './hooks/useCustomLogo'
import NavLoginDropdown from 'components/molecules/NavLoginDropdown'
import useWindowMatchMedia from 'hooks/useWindowMatchMedia'
import { BREAKPOINTS } from 'consts/breakpoints'

const Navbar = () => {
  const { userPoints, isLogged } = useContext(UserContext)
  const { logo } = useCustomLogo()
  const [showExternalLinks] = useWindowMatchMedia(BREAKPOINTS.tablet, 'min')
  const [hideLoginLinks] = useWindowMatchMedia(BREAKPOINTS.smallPhone, 'max')

  return (
    <nav>
      <Link className="nav__logo" to={PATHS.home}>
        <img className="logo__img" src={logo || DefaultLogo} />
      </Link>

      {isLogged ? (
        <div className="nav__menu">
          {showExternalLinks && (
            <div className="nav__links">
              <Link className="nav__link" to={PATHS.eduZone}>
                Strefa Edu
              </Link>
              <Link className="nav__link" to={PATHS.rewardShop}>
                Sklep
              </Link>
            </div>
          )}

          {userPoints !== null && (
            <div className="nav__points">
              <MedalIcon width={20} height={24} />
              <span>Twoje punkty</span>
              <span className="points__count">{userPoints}</span>
            </div>
          )}

          <NavDropdown />
        </div>
      ) : (
        <div className="nav__menu">
          {hideLoginLinks ? (
            <NavLoginDropdown />
          ) : (
            <>
              <div className="nav__links">
                <Link className="nav__link" to={PATHS.eduZone}>
                  Strefa Edu
                </Link>
              </div>
              <Link className="nav__link" to={PATHS.login}>
                <Button className="nav__btn">Zaloguj</Button>
              </Link>
              <Link className="nav__link" to={PATHS.register}>
                <Button className="nav__btn btn--outline">Załóż konto </Button>
              </Link>
            </>
          )}
        </div>
      )}
    </nav>
  )
}

export default Navbar
