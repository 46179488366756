import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'
import { IHomePageLandingCard } from '../models/homePageLandingCard'
import { IHomePageLandingImage } from '../models/homePageLandingImage'

const useHomePageLanding = () => {
  const { data: image } = useData<IHomePageLandingImage>(ENDPOINTS.homePageLandingImage)

  const { data: cards } = useData<IHomePageLandingCard[]>(ENDPOINTS.homePageLandingCards, {
    dataLocation: 'items'
  })

  return { image, cards }
}

export default useHomePageLanding
