import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'
import { IHomePageIconsItem } from '../models/homePageIconsItem'

const useHomePageIcons = () => {
  const { data: iconsItemList } = useData<IHomePageIconsItem[]>(ENDPOINTS.homePageIcons, {
    dataLocation: 'items'
  })

  return { iconsItemList }
}

export default useHomePageIcons
