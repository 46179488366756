import { IApiArticlesResponse } from 'models/responses/apiArticlesResponse'
import { IFormattedArticlesResponse } from 'models/responses/formattedArticlesResponse'
import { formatISODate } from 'utils/formatISODate'

export const transformArticlesResponseData = (
  data: IApiArticlesResponse
): IFormattedArticlesResponse => {
  const { items: articles, numberOfPosts: numberOfArticles } = data

  for (let i = 0; i < articles.length; i++) {
    articles[i].createdAt = formatISODate(articles[i].createdAt, 'dd MMMM yyyy')
    articles[i].updatedAt = formatISODate(articles[i].updatedAt, 'dd MMMM yyyy')
  }

  if (numberOfArticles === 1) {
    return {
      numberOfArticles,
      highlitedArticle: articles[0],
      restOfArticles: null
    }
  } else {
    return {
      numberOfArticles,
      highlitedArticle: articles[0],
      restOfArticles: articles.slice(1)
    }
  }
}
