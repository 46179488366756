import { RewardOrder } from 'components/atoms/RewardFilters/interface'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { RewardShopFiltersContext } from './context'

export const RewardShopFiltersContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [pointsRange, setPointsRange] = useState<[number, number]>([100, 20000])
  const [sortType, setSortType] = useState(RewardOrder.MOST_POPULAR)

  const params = useMemo(
    () =>
      new URLSearchParams({
        ordering: sortType,
        min: pointsRange[0].toString(),
        max: pointsRange[1].toString()
      }).toString(),
    [pointsRange, sortType]
  )

  const contextProps = useMemo(
    () => ({
      pointsRange,
      setPointsRange,
      sortType,
      setSortType,
      params
    }),
    [pointsRange, sortType, params]
  )

  return (
    <RewardShopFiltersContext.Provider value={contextProps}>
      {children}
    </RewardShopFiltersContext.Provider>
  )
}
