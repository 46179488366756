import { IRewardDetails } from 'models/rewardDetails'
import { generatePath } from 'react-router-dom'
import { ENDPOINTS } from 'utils/consts'
import useData from './useData'

const useRewardModalDetails = (id: number, options?: { dontFetchOnMount: true }) => {
  const { data, getData, isLoading } = useData<IRewardDetails>(
    generatePath(ENDPOINTS.rewardDetails, { id: `${id}` }),
    { dontFetchOnMount: options?.dontFetchOnMount }
  )

  return { data, getData, isLoading }
}

export default useRewardModalDetails
