import LineSeparator from 'components/atoms/LineSeparator'
import useArticleDetails from './hooks/useArticleDetails'
import './style.scss'
import ArticleDatesBox from './partials/ArticleDatesBox'
import ArticleSection from './partials/ArticleSection'
import ArticleAttachmentList from './partials/ArticleAttachmentList'
import ScrollToTopButton from 'components/molecules/ScrollToTopButton'

const ArticleDetailsPage = () => {
  const { articleDetails } = useArticleDetails()

  return (
    <div className="article-details-page">
      {articleDetails && (
        <>
          <img
            className="article-details-page__header-img"
            src={articleDetails.headerImage}
            alt="Główne zdjęcie artykułu"
          />
          <div className="article-details-page__content-wrapper">
            <ArticleDatesBox
              creationDate={articleDetails.createdAt}
              updateDate={articleDetails.updatedAt}
            />

            <div className="content-wrapper__heading">
              <h1 className="heading__title">{articleDetails.title}</h1>
              <LineSeparator />
            </div>

            {articleDetails.sections.map((section, index) => (
              <ArticleSection key={index} {...section} />
            ))}

            {articleDetails.attachments.length !== 0 && (
              <ArticleAttachmentList attachments={articleDetails.attachments} />
            )}
          </div>
          <div className="article-details-page__sroll-to-top-box">
            <ScrollToTopButton />
          </div>
        </>
      )}
    </div>
  )
}

export default ArticleDetailsPage
