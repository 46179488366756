/* eslint-disable no-unused-vars */
export interface RewardFiltersProps {
  rewardCount?: number
}

export enum RewardOrder {
  MOST_POPULAR = '-purchase_count',
  NEWEST = '-created_at',
  PRICE_UP = '-cost',
  PRICE_DOWN = 'cost'
}
