import { ISelectOption } from 'models/selectOption'

const url = window.location
export const API_URL =
  url.port !== '' ? `${url.protocol}//${url.hostname}:8000/api/` : `${url.origin}/api/`

export const ENDPOINTS = {
  googleAnalytics: 'ga-identificator/',

  user: 'user/',
  userPoints: 'user/points/',
  payoutData: 'user/bank-account/',
  shippingData: 'user/shipping-data/',
  fiscalData: 'user/fiscal-data/',
  candidates: 'candidates/',
  leadCategories: 'lead-categories/',
  taxOffices: 'tax-offices/',
  regions: 'regions/',
  getToken: 'token/',
  refreshToken: 'token/refresh/',
  resetPassword: 'reset-password/',
  changePassword: 'reset-password/confirm/',
  checkToken: 'reset-password/validate_token/',

  rewards: 'shop/rewards/',
  rewardDetails: 'shop/rewards/:id',
  purchaseReward: 'shop/rewards/buy/',
  purchaseHistory: 'user/purchase-history/',

  meeting: 'meeting/:hash/',
  meetingQR: 'meeting/:hash/qr/',

  candidateSurvey: 'candidates/:hash/survey/',
  candidateSurveyReject: 'candidates/:hash/reject/',
  candidateSurveyStatus: 'candidates/:hash/status/',
  candidateSurveyDetails: 'candidates/:hash/detail/',

  homePageLandingCards: 'customization/header-cards',
  homePageLandingImage: 'customization/header-image',
  homePageIntroduction: 'customization/introduction',
  homePageDescription: 'customization/description',
  homePageMetrics: 'customization/metric',
  homePageExpansion: 'customization/expansion',
  homePageExpansionImage: 'customization/expansion-image',
  homePageIcons: 'customization/icons',
  homePageSummary: 'customization/summary',

  customColors: 'customization/colors',
  customLogo: 'customization/logo',
  customProfileBgImg: 'customization/profile-image',
  customAuthBgImg: 'customization/login-image',

  messageAfterRecommendation: 'program/prompt1',
  messageAfterInviteRejection: 'program/prompt2',
  programInfo: 'program/program-information-content',
  recommendationTermsAndConditions: 'program/recommendation-terms-and-conditions',
  registrationTermsAndConditions: 'program/registration-terms-and-conditions',

  articles: 'edu/posts',
  articleDetails: 'edu/posts/:id'
}

export const PATHS = {
  home: '/',
  login: '/login',
  register: '/register',
  changePassword: '/change-password',
  profile: '/profile',
  settings: '/settings',
  forgotPassword: '/forgot-password',
  invite: '/invite',
  inviteHashed: '/invite/:hash',
  meeting: '/meeting',
  meetingHashed: '/meeting/:hash',
  rewardShop: '/reward-shop',
  eduZone: '/edu',
  articleDetails: '/edu/:id'
}

export const RECORDS_PER_PAGE = {
  candidates: 10,
  rewards: 9,
  purchasedItems: 9
}

export const LOCAL_STORAGE = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  candidateHash: 'candidateHash'
}

export const IDENTIFIERS: ISelectOption[] = [
  { value: 'nip', label: 'NIP' },
  { value: 'pesel', label: 'Pesel' }
]

export const VOIVODESHIPS: ISelectOption[] = [
  { value: 'lower_silesia', label: 'Dolnośląskie' },
  { value: 'kuyavia-pomerania', label: 'Kujawsko-Pomorskie' },
  { value: 'lublin', label: 'Lubelskie' },
  { value: 'lubusz', label: 'Lubuskie' },
  { value: 'lodz', label: 'Łódzkie' },
  { value: 'lesser_poland', label: 'Małopolskie' },
  { value: 'masovia', label: 'Mazowieckie' },
  { value: 'opole', label: 'Opolskie' },
  { value: 'subcarpatia', label: 'Podkarpackie' },
  { value: 'podlasie', label: 'Podlaskie' },
  { value: 'pomerania', label: 'Pomorskie' },
  { value: 'silesia', label: 'Śląskie' },
  { value: 'swietokrzyskie', label: 'Świętokrzyskie' },
  { value: 'warmia-masuria', label: 'Warmińsko-Mazurskie' },
  { value: 'greater_poland', label: 'Wielkopolskie' },
  { value: 'west_pomerania', label: 'Zachodniopomorskie' }
]
