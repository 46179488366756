import useHomePageMetrics from 'pages/HomePage/hooks/useHomePageMetrics'
import Metric from '../../components/Metric'
import './style.scss'

const MetricsSection = () => {
  const { metrics } = useHomePageMetrics()

  return (
    <section className="metrics-section">
      {metrics?.map((metric, index) => (
        <Metric
          key={index}
          value={metric.value}
          content={metric.content}
          link={{ text: metric.linkText, href: metric.linkUrl }}
        />
      ))}
    </section>
  )
}

export default MetricsSection
