import { IRegion } from 'models/region'
import { IApiListResponse } from 'models/responses/apiListResponse'
import { ISelectOption } from 'models/selectOption'
import { transformResponseDataToSelectOptions } from './toSelectOptions'

export const transformRegionsResponseData = (
  data: IApiListResponse<IRegion>
): [ISelectOption[], ISelectOption | undefined] => {
  const regionsOptions = transformResponseDataToSelectOptions(data.items)
  const defaultRegionOption = regionsOptions.find(({ value }) => value === data.default)

  return [regionsOptions, defaultRegionOption]
}
