import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axiosDefault from 'setup/axios/defaultInstance'
import { ENDPOINTS, PATHS } from 'utils/consts'
import { getUrlParams } from 'utils/getUrlParams'

export const useChangePasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const checkToken = useCallback(
    async (token: string | null) => {
      if (!token) {
        navigate(PATHS.forgotPassword, { state: { invalidToken: true } })
        return
      }

      try {
        setIsLoading(true)

        await axiosDefault.post(ENDPOINTS.checkToken, {
          token
        })
      } catch (error) {
        navigate(PATHS.forgotPassword, { state: { invalidToken: true } })
      } finally {
        setIsLoading(false)
      }
    },
    [navigate]
  )

  useEffect(() => {
    const token = getUrlParams('token')

    checkToken(token)
  }, [checkToken])

  return { isLoading }
}
