import { IShippingData } from 'models/shippingData'
import { TSplittedShippingData } from 'models/splittedShippingData'
import { partionArray } from 'utils/partionArray'

export const transformShippingDataResponseData = (data: IShippingData[]): TSplittedShippingData => {
  if (data.length > 1) {
    const [mainShippingData, otherShippingData] = partionArray(
      data,
      (item: IShippingData) => item.isDefault
    )

    return [mainShippingData[0], otherShippingData]
  } else if (data.length === 1) {
    return [data[0], null]
  } else {
    return [null, null]
  }
}
