import { IShippingData } from 'models/shippingData'
import { TSplittedShippingData } from 'models/splittedShippingData'
import { ENDPOINTS } from 'utils/consts'
import { transformShippingDataResponseData } from 'utils/TransformResponseData/shippingData'
import useData from './useData'

const useShippingData = (options?: { dontFetchOnMount: true }) => {
  const {
    data: shippingData,
    createData: createShippingData,
    updateData: updateShippingData,
    getData: getShippingData,
    ...rest
  } = useData<IShippingData, TSplittedShippingData, Omit<IShippingData, 'id'>>(
    ENDPOINTS.shippingData,
    {
      dontFetchOnMount: options?.dontFetchOnMount,
      dataLocation: 'items',
      transformResponseData: transformShippingDataResponseData
    }
  )

  const [mainShippingData, otherShippingData] = shippingData || []

  return {
    mainShippingData,
    otherShippingData,
    createShippingData,
    updateShippingData,
    getShippingData,
    ...rest
  }
}

export default useShippingData
