import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'
import { IHomePageMetric } from '../models/homePageMetric'

const useHomePageMetrics = () => {
  const { data: metrics } = useData<IHomePageMetric[]>(ENDPOINTS.homePageMetrics, {
    dataLocation: 'items'
  })

  return { metrics }
}

export default useHomePageMetrics
