import Button from 'components/atoms/Button'
import ThumbsUpIcon from 'assets/icons/thumbs-up.svg'
import './style.scss'
import { CandidateAddedProps } from './interface'
import { useFormContext } from 'react-hook-form'
import useMessageAfterRecommendation from './hooks/useMessageAfterRecommendation'

const CandidateAdded = ({ resetFormSteps }: CandidateAddedProps) => {
  const { reset } = useFormContext()
  const { messageAfterRecommendation } = useMessageAfterRecommendation()

  const resetRecommendationForm = () => {
    reset()
    resetFormSteps()
  }

  return (
    <div className="candidate-added">
      <h3 className="candidate-added__title">Gotowe!</h3>
      <p className="candidate-added__desc">{messageAfterRecommendation}</p>
      <img className="candidate-added__image" src={ThumbsUpIcon} />
      <div className="candidate-added__buttons">
        <Button onClick={resetRecommendationForm}>Poleć nowego znajomego</Button>
        <Button
          onClick={(e: any) => {
            e.preventDefault()
          }}
          className="btn--outline">
          Zobacz swoje polecenia
        </Button>
      </div>
    </div>
  )
}

export default CandidateAdded
