import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Link, useLocation } from 'react-router-dom'
import './style.scss'
import { PATHS } from 'utils/consts'
import { useEffect, useState } from 'react'

const NavLoginDropdown = () => {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    setIsOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <div className="nav-login-dropdown">
      <div className="nav-login-dropdown__header" onClick={handleToggle}>
        {isOpen ? <FiChevronUp /> : <FiChevronDown />}
      </div>
      {isOpen && (
        <div className="nav-login-dropdown__menu" id="nav-login-dropdown__menu">
          <Link to={PATHS.eduZone}>Strefa Edu</Link>

          <span className="nav-login-dropdown__divider" />

          <Link to={PATHS.login}>Zaloguj</Link>
          <Link to={PATHS.register}>Załóż konto</Link>
        </div>
      )}
    </div>
  )
}

export default NavLoginDropdown
