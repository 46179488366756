import { IUpdateUser } from 'models/requests/updateUser'
import { IServerResponse } from 'models/serverResponse'
import { IUser } from 'models/user'
import { UserContext } from 'providers/user/context'
import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axiosDefault from 'setup/axios/defaultInstance'
import { ENDPOINTS, PATHS } from 'utils/consts'
import { parseApiErrors } from 'utils/parseApiErrors'

const useUser = () => {
  const { updateUserData, logout } = useContext(UserContext)
  const navigate = useNavigate()

  const [userData, setUserData] = useState({} as IUser)
  const [userPoints, setUserPoints] = useState<number | null>(null)

  const getUserData = useCallback(async (): Promise<IServerResponse> => {
    try {
      const response = await axiosDefault.get(ENDPOINTS.user)

      setUserData(response.data)

      return { succeed: true }
    } catch (error) {
      return parseApiErrors(error)
    }
  }, [])

  const updateUser = useCallback(
    async (data: IUpdateUser): Promise<IServerResponse> => {
      try {
        await axiosDefault.patch(ENDPOINTS.user, data)

        updateUserData()

        return { succeed: true }
      } catch (error) {
        return parseApiErrors(error)
      }
    },
    [updateUserData]
  )

  const deleteUser = useCallback(async (): Promise<IServerResponse> => {
    try {
      await axiosDefault.delete(ENDPOINTS.user)

      logout()
      navigate(PATHS.login)

      return { succeed: true }
    } catch (error) {
      return parseApiErrors(error)
    }
  }, [logout, navigate])

  const getUserPoints = useCallback(async (): Promise<IServerResponse> => {
    try {
      const response = await axiosDefault.get(ENDPOINTS.userPoints)

      setUserPoints(response.data.currentPoints)

      return { succeed: true }
    } catch (error) {
      const serverResponse = parseApiErrors(error)

      if (serverResponse.errorCode === 'recommender_not_found') {
        setUserPoints(null)

        return { succeed: true }
      }

      return serverResponse
    }
  }, [])

  return { userData, userPoints, getUserData, updateUser, deleteUser, getUserPoints }
}

export default useUser
