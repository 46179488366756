import useData from 'hooks/useData'
import { ENDPOINTS } from 'utils/consts'

export const useProgramInfo = () => {
  const { data: programInfo } = useData<string>(ENDPOINTS.programInfo, {
    dataLocation: 'programInformationContent'
  })

  return { programInfo }
}
