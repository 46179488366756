import LineSeparator from 'components/atoms/LineSeparator'
import Tile from 'components/atoms/Tile'
import useHomePageDescription from 'pages/HomePage/hooks/useHomePageDescription'
import DescriptionStepItem from '../../components/DescriptionStepItem'
import './style.scss'
import parse from 'html-react-parser'

const DescriptionSection = () => {
  const { data } = useHomePageDescription()

  const firstBlockSteps = data?.descriptionSteps.slice(0, 4)
  const secondBlockSteps = data?.descriptionSteps.slice(4, 8)

  return (
    <section className="description-section">
      <div className="description-section__bg" />
      <div className="description-section__content-wrapper">
        <div className="content-wrapper__description">
          <h1 className="description__title">{data?.descriptionTitle}</h1>
          <LineSeparator color="white" />
          <p className="description__content">{parse(data?.descriptionContent || '')}</p>
        </div>
        <Tile className="content-wrapper__steps" shadow="normal">
          {firstBlockSteps && (
            <ul className="steps__block steps__block--from-down">
              {firstBlockSteps.map((step, index) => (
                <DescriptionStepItem key={index} title={step.title} content={step.content} />
              ))}
              <div className="steps__space-box" />
            </ul>
          )}
          {secondBlockSteps && (
            <ul className="steps__block steps__block--from-up">
              <div className="steps__space-box" />
              {secondBlockSteps.map((step, index) => {
                return <DescriptionStepItem key={index} title={step.title} content={step.content} />
              })}
            </ul>
          )}
        </Tile>
      </div>
    </section>
  )
}

export default DescriptionSection
